import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements AfterViewInit {
  public chart: any;
  public currentYear: number;

  monthLabels: string[];
  monthlyData: number[];
  currentMonthIndex: number;

  constructor(private router: Router) {
    this.monthLabels = this.getMonthLabels();
    this.currentMonthIndex = new Date().getMonth();
    this.monthlyData = this.getMonthData();
    this.currentYear = new Date().getFullYear();
  }

  ngAfterViewInit(): void {
    this.barChart();
  }

  private getMonthLabels(): string[] {
    return [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ];
  }

  private getMonthData(): number[] {
    return [467, 576, 572, 79, 92, 574, 573, 576, 467, 576, 572, 79];
  }

  private barChart() {
    this.chart = new Chart('MyChart', {
      type: 'bar',
      data: {
        labels: this.monthLabels, // Default to monthly labels
        datasets: [
          {
            label: 'Consommation en kWh',
            data: this.monthlyData, // Default to monthly data
            backgroundColor: '#1A3B9A',
            borderRadius: 8,
          },
        ],
      },
      options: {
        aspectRatio: 2.5,
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  }

  get currentMonthData(): number {
    return this.monthlyData[this.currentMonthIndex];
  }

  navigateToInvoice() {
    this.router.navigate(['/dashboard/invoice']);
  }
}
