<section class="w-full h-full dark:text-usual-100">
  <!-- start charge if enabled and not already in charge -->
  <div
    *ngIf="
      (rechargeUser?.enabled === true &&
        (rechargeUser?.currentChargeSessionSessionId === null ||
          rechargeUser?.currentChargeSessionSessionId?.trim() === null)) ||
      rechargeUser?.currentChargeSessionSessionId === '' ||
        rechargeUser?.currentChargeSessionSessionId?.trim() === ''
    "
    class="gap-8"
  >
    <div class="flex flex-col gap-4">
      <form
        [formGroup]="EvseForm"
        (ngSubmit)="startRemoteAuthorization()"
        class="flex flex-col gap-6"
      >
        <div class="flex flex-col justify-between gap-2">
          <div
            class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
          >
            <label class="font-bold min-w-max" for="evseID">EVSE ID</label>
            <input
              id="evseID"
              type="text"
              class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300 disabled:text-usual-400"
              formControlName="evseID"
              placeholder="{{
                'pages.recharge.evseID.evseID_placeholder' | translate
              }}"
              name="evseID"
              required
            />
          </div>
        </div>

        <button
          [disabled]="EvseForm.invalid"
          class="bg-gradient-to-br disabled:from-usual-100 disabled:to-usual-100 disabled:dark:from-usual-800 disabled:dark:to-usual-800 disabled:text-usual-400 from-primary-500 to-primary-200 hover:from-primary-400 hover:to-secondary-500 text-usual-50"
        >
          {{ "pages.recharge.evseID.button_start_charge" | translate }}
        </button>
      </form>
    </div>
  </div>

  <!-- stop charge -->
  <div
    *ngIf="
      rechargeUser?.enabled === true &&
      rechargeUser?.currentChargeSessionSessionId !== null &&
      this.rechargeUser?.currentChargeSessionSessionId !== ''
    "
    class="gap-8"
  >
    <div class="flex flex-col gap-4">
      <img
        class="object-contain h-60 w-87"
        src="assets/imgs/chargement.png"
        alt="voiture en cours de chargement"
      />
      <button
        class="btn btn-danger bg-orange-300"
        (click)="stopRemoteAuthorization()"
      >
        Arreter la charge à distance
      </button>
    </div>
  </div>

  <!--if user is not enabled-->
  <div
    *ngIf="this.rechargeUser && this.rechargeUser?.enabled === false"
    class="grid grid-cols-12 gap-8"
  >
    <div
      *ngIf="this.rechargeUser && rechargeUser?.hasPaymentMethod === false"
      class="flex flex-col col-span-12 md:col-span-6 bg-usual-50 dark:bg-usual-800 rounded-2xl p-6 gap-6 relative"
    >
      <!-- if no bank card-->
      <h2 class="text-primary-200">
        {{ "pages.recharge.card-1.headline" | translate }}
      </h2>
      <form (ngSubmit)="addACard()" class="flex flex-col gap-6">
        <span id="card-element"></span>
        <button
          type="submit"
          class="px-4 py-2.5 w-max bg-primary-500 hover:bg-primary-300 dark:bg-primary-300 dark:hover:bg-primary-500 text-usual-50 rounded-lg italic text-center font-bold"
        >
          {{ "pages.recharge.card-1.button_save_card" | translate }}
        </button>
      </form>
    </div>

    <!-- if no address-->
    <div
      *ngIf="this.rechargeUser && this.rechargeUser?.addresses?.length === 0"
      class="flex flex-col col-span-12 md:col-span-6 bg-usual-50 dark:bg-usual-800 rounded-2xl p-6 gap-6 relative"
    >
      <h2 class="text-primary-200">
        {{ "pages.recharge.card-2.headline" | translate }}
      </h2>
      <form
        [formGroup]="AddressForm"
        (ngSubmit)="createAddress()"
        class="flex flex-col gap-6"
      >
        <!-- City Field -->
        <div class="flex flex-col gap-2 w-full">
          <div
            class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
          >
            <label for="city" class="font-bold">{{
              "pages.recharge.card-2.city_label" | translate
            }}</label>
            <input
              type="text"
              formControlName="city"
              id="city"
              placeholder="{{
                'pages.recharge.card-2.city_placeholder' | translate
              }}"
              class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
            />
          </div>
        </div>
        <div class="flex flex-col gap-2 w-full">
          <div
            class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
          >
            <label for="streetNumber" class="font-bold">{{
              "pages.recharge.card-2.streetNumber_label" | translate
            }}</label>
            <input
              type="number"
              formControlName="streetNumber"
              id="streetNumber"
              placeholder="{{
                'pages.recharge.card-2.streetNumber_placeholder' | translate
              }}"
              class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
            />
          </div>
        </div>
        <div class="flex flex-col gap-2 w-full">
          <div
            class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
          >
            <label for="street" class="font-bold">{{
              "pages.recharge.card-2.street_label" | translate
            }}</label>
            <input
              type="text"
              formControlName="street"
              id="street"
              placeholder="{{
                'pages.recharge.card-2.street_placeholder' | translate
              }}"
              class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
            />
          </div>
        </div>
        <div class="flex flex-col gap-2 w-full">
          <div
            class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
          >
            <label for="complement" class="font-bold">{{
              "pages.recharge.card-2.complement_label" | translate
            }}</label>
            <input
              type="text"
              formControlName="complement"
              id="complement"
              placeholder="{{
                'pages.recharge.card-2.complement_placeholder' | translate
              }}"
              class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
            />
          </div>
        </div>
        <div class="flex flex-col gap-2 w-full">
          <div
            class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
          >
            <label for="zipCode" class="font-bold">{{
              "pages.recharge.card-2.zipCode_label" | translate
            }}</label>
            <input
              type="text"
              formControlName="zipCode"
              id="zipCode"
              placeholder="{{
                'pages.recharge.card-2.zipCode_placeholder' | translate
              }}"
              class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
            />
          </div>
        </div>
        <div class="flex flex-col gap-2 w-full">
          <div
            class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
          >
            <label for="region" class="font-bold">{{
              "pages.recharge.card-2.region_label" | translate
            }}</label>
            <input
              type="text"
              formControlName="region"
              id="region"
              placeholder="{{
                'pages.recharge.card-2.region_placeholder' | translate
              }}"
              class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
            />
          </div>
        </div>

        <button
          type="submit"
          class="px-4 py-2.5 w-max bg-primary-500 hover:bg-primary-300 dark:bg-primary-300 dark:hover:bg-primary-500 text-usual-50 rounded-lg italic text-center font-bold"
        >
          {{ "pages.recharge.card-2.button_save_address" | translate }}
        </button>
      </form>
    </div>
  </div>

  <!-- Toast Alert Message -->
  <div
    *ngIf="isToastVisible"
    [ngClass]="{
      'bg-positive-100 text-positive-500 dark:bg-positive-800 dark:text-positive-200':
        toastType === 'success',
      'bg-negative-100 text-negative-500 dark:bg-negative-800 dark:text-negative-200':
        toastType === 'danger',
    }"
    class="flex fixed right-4 bottom-0 items-center w-full max-w-xs p-4 mb-4 text-usual-500 rounded-lg shadow dark:text-usual-400 dark:bg-usual-800"
    role="alert"
  >
    <div
      class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8"
      [ngClass]="{
        'text-positive-500 bg-positive-100 dark:bg-positive-800 dark:text-positive-200':
          toastType === 'success',
        'text-negative-500 bg-negative-100 dark:bg-negative-800 dark:text-negative-200':
          toastType === 'danger',
      }"
    >
      <svg
        class="w-5 h-5"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          [attr.d]="
            toastType === 'success'
              ? 'M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z'
              : 'M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z'
          "
        />
      </svg>
      <span class="sr-only">
        {{ toastType === "success" ? "Check icon" : "Error icon" }}
      </span>
    </div>
    <div class="ms-3 text-sm font-normal">
      {{ toastMessage }}
    </div>
    <button
      type="button"
      class="ms-auto -mx-1.5 -my-1.5 bg-usual-50 text-usual-400 hover:text-usual-900 rounded-lg focus:ring-2 focus:ring-usual-300 p-1.5 hover:bg-usual-100 inline-flex items-center justify-center h-8 w-8 dark:text-usual-500 dark:hover:text-usual-50 dark:bg-usual-800 dark:hover:bg-usual-700"
      data-dismiss-target="#toast-success"
      aria-label="Close"
    >
      <span class="sr-only">Close</span>
      <svg
        class="w-3 h-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
    </button>
  </div>
</section>
