<section
  class="flex flex-col justify-between h-screen dark:bg-usual-800 bg-[url('/assets/imgs/bg_auth.png')] bg-no-repeat bg-right bg-contain"
>
  <div class="py-4 px-4 md:px-8">
    <div class="max-w-md md:max-w-xl mx-auto flex flex-col gap-8">
      <div class="flex flex-col gap-6 items-center justify-center">
        <img
          class="h-32"
          src="../../../assets/logo/efusion-icon.png"
          alt="efusion-icon"
        />
        <h1>{{ "authentication.login.headline" | translate }}</h1>
      </div>
      <form
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit()"
        class="flex flex-col gap-4 items-center"
      >
        <div class="flex flex-col gap-2 w-full">
          <!-- Email Field Start -->
          <div
            class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
          >
            <label class="font-bold min-w-max" for="Email"
              >{{ "authentication.login.email_label" | translate }} *</label
            >
            <input
              matInput
              type="email"
              autocomplete="email"
              formControlName="email"
              placeholder="{{
                'authentication.login.email_placeholder' | translate
              }}"
              class="border-0 outline-0 focus:ring-0 w-full bg-transparent dark:bg-usual-50 rounded-lg caret-primary-500 dark:caret-primary-300"
            />
          </div>
          <mat-error *ngIf="email?.invalid && email?.touched">
            <span
              class="text-negative-500 dark:text-negative-300 font-bold"
              *ngIf="email?.errors?.['required']"
              >{{
                "authentication.login.email_required_error" | translate
              }}</span
            >
            <span
              class="text-negative-500 dark:text-negative-300 font-bold"
              *ngIf="email?.errors?.['email']"
              >{{ "authentication.login.email_syntax_error" | translate }}</span
            >
          </mat-error>
        </div>
        <!-- Email Field End -->
        <div class="flex flex-col gap-2 w-full">
          <!-- Password Field Start -->
          <div
            class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
          >
            <label class="font-bold min-w-max" for="Email"
              >{{ "authentication.login.password_label" | translate }} *</label
            >
            <input
              matInput
              type="password"
              formControlName="password"
              autocomplete="current-password"
              placeholder="{{
                'authentication.login.password_placeholder' | translate
              }}"
              [type]="hidePassword ? 'password' : 'text'"
              required
              class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
            />
            <mat-icon
              matSuffix
              class="icon-clickable min-w-max"
              (click)="hidePassword = !hidePassword"
              >{{ hidePassword ? "visibility" : "visibility_off" }}</mat-icon
            >
          </div>
          <mat-error *ngIf="password?.invalid && password?.touched">
            <span
              class="text-negative-500 dark:text-negative-300 font-bold"
              *ngIf="password?.errors?.['required']"
              >{{
                "authentication.login.password_required_error" | translate
              }}</span
            >
            <span
              class="text-negative-500 dark:text-negative-300 font-bold"
              *ngIf="password?.errors?.['pattern']"
              >{{
                "authentication.login.password_syntax_error" | translate
              }}</span
            >
          </mat-error>
        </div>
        <!-- Password Field End -->
        <div
          *ngIf="errorMessage"
          class="text-negative-500 dark:text-negative-300 font-bold mb-4"
        >
          {{ errorMessage }}
        </div>
        <div class="flex flex-col gap-4 items-center justify-center">
          <div class="flex flex-col gap-2 items-center">
            <button
              [disabled]="loginForm.invalid"
              type="submit"
              class="bg-gradient-to-br disabled:from-usual-100 disabled:to-usual-100 disabled:dark:from-usual-800 disabled:dark:to-usual-800 disabled:text-usual-400 from-primary-500 to-primary-200 hover:from-primary-400 hover:to-secondary-500 text-usual-50"
            >
              {{ "authentication.login.button_create_account" | translate }}
            </button>
            <p class="text-usual-600 text-xs">
              * {{ "authentication.login.required_field_text" | translate }}
            </p>
          </div>
          <a
            href="/auth/resetpassword"
            class="text-primary-500 dark:text-primary-300 hover:text-primary-300 dark:hover:text-primary-500 underline font-bold italic px-4 py-2.2"
            >{{
              "authentication.login.nagivate_resetpassword_screen" | translate
            }}</a
          >
        </div>
      </form>
    </div>
  </div>
  <div class="py-12 px-4 md:px-8 bg-usual-950 text-usual-50">
    <div class="max-w-md md:max-w-2xl mx-auto">
      <div class="flex flex-col gap-6 text-center">
        <h2>
          {{ "authentication.login.firstconnection_headline" | translate }}
        </h2>
        <p class="text-usual-300">
          {{ "authentication.login.firstconnection_subheadline" | translate }}
        </p>
        <div class="flex justify-center">
          <a
            href="/auth/register"
            class="px-4 py-2.5 bg-primary-500 hover:bg-primary-300 dark:bg-primary-300 dark:hover:bg-primary-500 text-usual-50 rounded-lg"
            >{{
              "authentication.login.nagivate_register_screen" | translate
            }}</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
