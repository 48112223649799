var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import instances from '../../dom/instances';
var Default = {
  defaultTabId: null,
  activeClasses: 'text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 border-blue-600 dark:border-blue-500',
  inactiveClasses: 'dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
  onShow: function () {}
};
var DefaultInstanceOptions = {
  id: null,
  override: true
};
var Tabs = /** @class */function () {
  function Tabs(tabsEl, items, options, instanceOptions) {
    if (tabsEl === void 0) {
      tabsEl = null;
    }
    if (items === void 0) {
      items = [];
    }
    if (options === void 0) {
      options = Default;
    }
    if (instanceOptions === void 0) {
      instanceOptions = DefaultInstanceOptions;
    }
    this._instanceId = instanceOptions.id ? instanceOptions.id : tabsEl.id;
    this._tabsEl = tabsEl;
    this._items = items;
    this._activeTab = options ? this.getTab(options.defaultTabId) : null;
    this._options = __assign(__assign({}, Default), options);
    this._initialized = false;
    this.init();
    instances.addInstance('Tabs', this, this._tabsEl.id, true);
    instances.addInstance('Tabs', this, this._instanceId, instanceOptions.override);
  }
  Tabs.prototype.init = function () {
    var _this = this;
    if (this._items.length && !this._initialized) {
      // set the first tab as active if not set by explicitly
      if (!this._activeTab) {
        this.setActiveTab(this._items[0]);
      }
      // force show the first default tab
      this.show(this._activeTab.id, true);
      // show tab content based on click
      this._items.map(function (tab) {
        tab.triggerEl.addEventListener('click', function (event) {
          event.preventDefault();
          _this.show(tab.id);
        });
      });
    }
  };
  Tabs.prototype.destroy = function () {
    if (this._initialized) {
      this._initialized = false;
    }
  };
  Tabs.prototype.removeInstance = function () {
    this.destroy();
    instances.removeInstance('Tabs', this._instanceId);
  };
  Tabs.prototype.destroyAndRemoveInstance = function () {
    this.destroy();
    this.removeInstance();
  };
  Tabs.prototype.getActiveTab = function () {
    return this._activeTab;
  };
  Tabs.prototype.setActiveTab = function (tab) {
    this._activeTab = tab;
  };
  Tabs.prototype.getTab = function (id) {
    return this._items.filter(function (t) {
      return t.id === id;
    })[0];
  };
  Tabs.prototype.show = function (id, forceShow) {
    var _a, _b;
    var _this = this;
    if (forceShow === void 0) {
      forceShow = false;
    }
    var tab = this.getTab(id);
    // don't do anything if already active
    if (tab === this._activeTab && !forceShow) {
      return;
    }
    // hide other tabs
    this._items.map(function (t) {
      var _a, _b;
      if (t !== tab) {
        (_a = t.triggerEl.classList).remove.apply(_a, _this._options.activeClasses.split(' '));
        (_b = t.triggerEl.classList).add.apply(_b, _this._options.inactiveClasses.split(' '));
        t.targetEl.classList.add('hidden');
        t.triggerEl.setAttribute('aria-selected', 'false');
      }
    });
    // show active tab
    (_a = tab.triggerEl.classList).add.apply(_a, this._options.activeClasses.split(' '));
    (_b = tab.triggerEl.classList).remove.apply(_b, this._options.inactiveClasses.split(' '));
    tab.triggerEl.setAttribute('aria-selected', 'true');
    tab.targetEl.classList.remove('hidden');
    this.setActiveTab(tab);
    // callback function
    this._options.onShow(this, tab);
  };
  Tabs.prototype.updateOnShow = function (callback) {
    this._options.onShow = callback;
  };
  return Tabs;
}();
export function initTabs() {
  document.querySelectorAll('[data-tabs-toggle]').forEach(function ($parentEl) {
    var tabItems = [];
    var activeClasses = $parentEl.getAttribute('data-tabs-active-classes');
    var inactiveClasses = $parentEl.getAttribute('data-tabs-inactive-classes');
    var defaultTabId = null;
    $parentEl.querySelectorAll('[role="tab"]').forEach(function ($triggerEl) {
      var isActive = $triggerEl.getAttribute('aria-selected') === 'true';
      var tab = {
        id: $triggerEl.getAttribute('data-tabs-target'),
        triggerEl: $triggerEl,
        targetEl: document.querySelector($triggerEl.getAttribute('data-tabs-target'))
      };
      tabItems.push(tab);
      if (isActive) {
        defaultTabId = tab.id;
      }
    });
    new Tabs($parentEl, tabItems, {
      defaultTabId: defaultTabId,
      activeClasses: activeClasses ? activeClasses : Default.activeClasses,
      inactiveClasses: inactiveClasses ? inactiveClasses : Default.inactiveClasses
    });
  });
}
if (typeof window !== 'undefined') {
  window.Tabs = Tabs;
  window.initTabs = initTabs;
}
export default Tabs;
