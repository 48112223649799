import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { User } from '../../models/User';
import { CognitoService } from '../../services/cognito';
import { UserService } from '../../services/UserService/user.service';
import { Users } from '../../utils/Users';
@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent {
  verificationForm: FormGroup;
  registerForm: FormGroup;
  public showModal = true;
  public hidePassword = true;
  public errorMessage: string | null = null;

  you: User | null = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private cognitoService: CognitoService,
    private userService: UserService,
  ) {
    this.registerForm = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      passwords: this.fb.group(
        {
          password: [
            '',
            [
              Validators.required,
              Users.validatePassword,
              Users.passwordWithNoSpace,
            ],
          ],
          confirmPassword: ['', Validators.required],
        },
        { validators: Users.validateEqual },
      ),
    });
    this.verificationForm = this.fb.group({
      code1: ['', Validators.required],
      code2: ['', Validators.required],
      code3: ['', Validators.required],
      code4: ['', Validators.required],
      code5: ['', Validators.required],
      code6: ['', Validators.required],
    });
  }

  get formControls() {
    return this.registerForm.controls;
  }

  redirectToRegisterPage(): void {
    window.open('https://espace-b2b.efusion-ve.com/pages-register', '_blank');
  }

  get email() {
    return this.registerForm.get('email');
  }

  get password() {
    return this.registerForm.get('passwords.password');
  }

  username = '';

  onSubmit() {
    console.log(this.you);
    if (this.registerForm.valid) {
      const email = this.registerForm.value.email;
      const password = this.registerForm.get('passwords.password')?.value;
      this.cognitoService
        .cognitoSignUp(email, password)
        .then((data) => {
          console.log('Form Submitted', this.registerForm.value);
          console.log(data);
          this.username = data.userId;
          const newUser: User = {
            username: data.userId,
            userPoolId: environment.cognito.userPoolId,
            birthDate: '',
            email: this.registerForm.value.email,
            addresses: [],
            vehicules: [],
            phoneNumber: '',
            firstName: this.registerForm.value.firstname,
            lastName: this.registerForm.value.lastname,
            stripeCustomerId: '',
            enabled: false,
            hasPaymentMethod: false,
            rfididentification: {
              uID: '',
            },
            currentChargeSessionSessionId: null,
          };
          this.userService.createUser(newUser).subscribe({
            next(value) {
              console.log(value);
            },
            error(err) {
              console.error(err);
            },
          });
        })
        .catch((error: unknown) => {
          console.log('Erreur de connexion :', error);
          if (error instanceof Error) {
            this.errorMessage = error.message;
          } else {
            this.errorMessage =
              'La connexion a échoué. Veuillez vérifier vos identifiants et réessayer.';
          }
        });
      this.showModal = true;
    } else {
      console.log('Form Submitted error');
      Object.keys(this.registerForm.controls).forEach((field) => {
        const control = this.registerForm.get(field);
        if (control instanceof FormGroup) {
          Object.keys(control.controls).forEach((subField) => {
            const subControl = control.get(subField);
            subControl?.markAsTouched({ onlySelf: true });
          });
        } else {
          control?.markAsTouched({ onlySelf: true });
        }
      });
      this.errorMessage = 'Please correct the errors in the form';
    }
  }

  onSubmitCode() {
    if (this.verificationForm.valid) {
      console.log('Form is valid');
      const code1 = this.verificationForm.value.code1;
      const code2 = this.verificationForm.value.code2;
      const code3 = this.verificationForm.value.code3;
      const code4 = this.verificationForm.value.code4;
      const code5 = this.verificationForm.value.code5;
      const code6 = this.verificationForm.value.code6;

      const confirmationCode = code1 + code2 + code3 + code4 + code5 + code6;

      this.cognitoService
        .cognitoConfirmationCode(this.username, confirmationCode)
        .then((result) => {
          console.log('Confirmation successful:', result);
          this.userService
            .confirmUser(environment.cognito.userPoolId, this.username)
            .subscribe({
              next(value) {
                console.log(value);
              },

              error(err) {
                console.error(err);
              },
            });
        })
        .catch((error) => {
          console.log('Error confirming code:', error);
        });
    } else {
      console.log('Form is invalid:', this.verificationForm.errors);
    }
  }

  moveFocus(event: Event, nextElementId: string): void {
    const input = event.target as HTMLInputElement;

    if (input.value.length === 1) {
      const nextInput = document.getElementById(
        nextElementId,
      ) as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  handleBackspace(event: KeyboardEvent, prevElementId: string): void {
    if (event.key === 'Backspace') {
      const prevInput = document.getElementById(
        prevElementId,
      ) as HTMLInputElement;
      if (prevInput) {
        prevInput.focus();
      }
    }
  }
}
