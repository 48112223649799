import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-invoices',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './invoices.component.html',
  styleUrl: './invoices.component.scss'
})
export class InvoicesComponent {
  data: any[] = [];

  constructor() {
    for (let i = 0; i < 12; i++) {
      const dateFacturation = new Date(2024, 4 + i, 28);
      const numeroFacture = `B81C7148-000${i + 1}`;

      const randomIndex = Math.floor(Math.random() * 3); 
      let status;
      switch (randomIndex) {
        case 0:
          status = 'In Progress';
          break;
        case 1:
          status = 'Done';
          break;
        case 2:
          status = 'Denied';
          break;
      }

      this.data.push({
        dateFacturation: dateFacturation.toLocaleString(),
        numeroFacture: numeroFacture,
        status: status
      });
    }
  }
}
