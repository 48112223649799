import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CheckoutPaymentIntentResponse } from '../../models/CheckoutPaymentIntentResponse';
import { User } from '../../models/User';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private API_URL = environment.API_URL;
  constructor(private http: HttpClient) {}

  getUserInformations(userPoolId: string, username: string): Observable<User> {
    return this.http.get<User>(
      `${this.API_URL}v1/users/${userPoolId}/${username}`,
    );
  }

  updateUserInfo(userInfo: User): Observable<User> {
    return this.http.put<User>(`${this.API_URL}v1/users/update-info`, userInfo);
  }

  startRemoteAuthorization(
    userPoolId: string,
    username: string,
    evseId: string,
    uid: string,
  ): Observable<User> {
    const payload = {
      evseID: evseId,
      uid: uid,
    };
    return this.http.post<User>(
      `${this.API_URL}unifier/remote-authorize/start/user/${username}/${userPoolId}`,
      payload,
    );
  }

  stopRemoteAuthorization(
    userPoolId: string,
    username: string,
    evseId: string,
    sessionId: string,
  ): Observable<User> {
    const payload = {
      evseID: evseId,
      sessionID: sessionId,
    };
    return this.http.post<User>(
      `${this.API_URL}unifier/remote-authorize/stop/user/${username}/${userPoolId}`,
      payload,
    );
  }

  enableUser(userId: number): Observable<User> {
    return this.http.get<User>(`${this.API_URL}v1/users/${userId}/enable`);
  }

  setUpIntentForStripe(
    userId: number,
  ): Observable<CheckoutPaymentIntentResponse> {
    return this.http.get<CheckoutPaymentIntentResponse>(
      `${this.API_URL}v1/stripe/user/${userId}/save-payment-method`,
    );
  }

  confirmPaymentMethod(userId: number): Observable<User> {
    return this.http.get<User>(
      `${this.API_URL}v1/users/${userId}/confirm-payment-method`,
    );
  }

  createUser(payload: User): Observable<User> {
    return this.http.post<User>(
      `${this.API_URL}v1/users/registration/create`,
      payload,
    );
  }

  confirmUser(userPoolId: string, userName: string): Observable<User> {
    return this.http.get<User>(
      `${this.API_URL}v1/users/${userPoolId}/${userName}/confirm`,
    );
  }
}
