<section
  class="p-6 bg-usual-50 dark:bg-usual-900 drop-shadow-lg dark:text-usual-100 border-usual-200 dark:border-usual-700 rounded-2xl flex flex-col gap-2"
>
  <a
    href="dashboard/mon-compte"
    class="flex flex-row gap-2 items-center text-blue-500 hover:underline"
  >
    <svg
      class="h-6"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <!-- Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
      <path
        d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
      />
    </svg>
    <span>{{ "pages.update-password.return_profile" | translate }}</span>
  </a>

  <div class="py-20 md:px-8 flex flex-col gap-10">
    <div class="max-w-md md:max-w-2xl mx-auto flex flex-col gap-8">
      <div class="flex flex-col gap-6 items-center justify-center text-center">
        <img
          class="h-32"
          src="../../../assets/logo/efusion-icon.png"
          alt="Logo d'e-Fusion"
        />
        <h2>{{ "pages.update-password.headline" | translate }}</h2>
      </div>
      <form [formGroup]="updatePasswordForm" (ngSubmit)="onSubmit()">
        <div class="flex flex-col gap-4 w-full">
          <div
            class="px-2 md:px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-col md:flex-row md:items-center gap-2 w-full"
          >
            <label class="font-bold min-w-max px-3" for="oldPassword">{{
              "pages.update-password.old-password_label" | translate
            }}</label>
            <div
              class="flex flex-row gap-4 items-center relative w-full justify-between"
            >
              <input
                id="oldPassword"
                formControlName="oldPassword"
                type="password"
                placeholder="{{
                  'pages.update-password.old-password_placeholder' | translate
                }}"
                [type]="hidePassword ? 'password' : 'text'"
                required
                class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
              />
              <mat-icon
                matSuffix
                class="icon-clickable absolute right-0 pr-3 cursor-pointer"
                (click)="hidePassword = !hidePassword"
                >{{ hidePassword ? "visibility" : "visibility_off" }}</mat-icon
              >
            </div>
          </div>
          <span
            class="text-negative-500 dark:text-negative-300 font-bold"
            *ngIf="
              updatePasswordForm.get('oldPassword')?.invalid &&
              updatePasswordForm.get('oldPassword')?.touched
            "
            >{{
              "pages.update-password.old-password_required_error" | translate
            }}</span
          >
          <div
            class="px-2 md:px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-col md:flex-row md:items-center gap-2 w-full"
          >
            <label class="font-bold min-w-max px-3" for="newPassword">{{
              "pages.update-password.new-password_label" | translate
            }}</label>
            <div
              class="flex flex-row gap-4 items-center relative w-full justify-between"
            >
              <input
                id="newPassword"
                formControlName="newPassword"
                type="password"
                placeholder="{{
                  'pages.update-password.new-password_placeholder' | translate
                }}"
                [type]="hidePassword ? 'password' : 'text'"
                required
                class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
              />
              <mat-icon
                matSuffix
                class="icon-clickable min-w-max"
                (click)="hidePassword = !hidePassword"
                >{{ hidePassword ? "visibility" : "visibility_off" }}</mat-icon
              >
            </div>
          </div>
          <span
            class="text-negative-500 dark:text-negative-300 font-bold"
            *ngIf="
              updatePasswordForm.get('newPassword')?.invalid &&
              updatePasswordForm.get('newPassword')?.touched
            "
            >{{
              "pages.update-password.new-password_required_error" | translate
            }}</span
          >
          <span
            class="text-negative-500 dark:text-negative-300 font-bold"
            *ngIf="updatePasswordForm.get('newPassword')?.errors?.['minlength']"
            >{{
              "pages.update-password.new-password_syntax_error" | translate
            }}</span
          >
          <div class="flex flex-col gap-4 items-center justify-center">
            <div class="flex flex-col gap-2 items-center">
              <button
                type="submit"
                [disabled]="updatePasswordForm.invalid"
                class="bg-gradient-to-br disabled:from-usual-100 disabled:to-usual-100 disabled:dark:from-usual-800 disabled:dark:to-usual-800 disabled:text-usual-400 from-primary-500 to-primary-200 hover:from-primary-400 hover:to-secondary-500 text-usual-50"
              >
                {{ "pages.update-password.button_change_password" | translate }}
              </button>
              <p class="text-usual-600 text-xs">
                *
                {{
                  "authentication.reset-password.required_field_text"
                    | translate
                }}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="errorMessage" class="flex flex-row justify-center">
          <p class="text-negative-500 dark:text-negative-300 font-bold">
            {{ errorMessage }}
          </p>
        </div>
      </form>
    </div>
  </div>
</section>
<!-- Toast Alert Message -->
<div
  id="toast-success"
  *ngIf="isToastVisible"
  [ngClass]="{
    'bg-positive-100 text-positive-500 dark:bg-positive-800 dark:text-positive-200':
      toastType === 'success',
    'bg-negative-100 text-negative-500 dark:bg-negative-800 dark:text-negative-200':
      toastType === 'danger',
  }"
  class="flex fixed right-4 bottom-0 items-center w-full max-w-xs p-4 mb-4 text-usual-500 rounded-lg shadow dark:text-usual-400 dark:bg-usual-800"
  role="alert"
>
  <div
    class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8"
    [ngClass]="{
      'text-positive-500 bg-positive-100 dark:bg-positive-800 dark:text-positive-200':
        toastType === 'success',
      'text-negative-500 bg-negative-100 dark:bg-negative-800 dark:text-negative-200':
        toastType === 'danger',
    }"
  >
    <svg
      class="w-5 h-5"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path
        [attr.d]="
          toastType === 'success'
            ? 'M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z'
            : 'M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z'
        "
      />
    </svg>
    <span class="sr-only">
      {{ toastType === "success" ? "Check icon" : "Error icon" }}
    </span>
  </div>
  <div class="ms-3 text-sm font-normal">
    {{ toastMessage }}
  </div>
  <button
    type="button"
    class="ms-auto -mx-1.5 -my-1.5 bg-usual-50 text-usual-400 hover:text-usual-900 rounded-lg focus:ring-2 focus:ring-usual-300 p-1.5 hover:bg-usual-100 inline-flex items-center justify-center h-8 w-8 dark:text-usual-500 dark:hover:text-usual-50 dark:bg-usual-800 dark:hover:bg-usual-700"
    data-dismiss-target="#toast-success"
    aria-label="Close"
  >
    <span class="sr-only">Close</span>
    <svg
      class="w-3 h-3"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
      />
    </svg>
  </button>
</div>
