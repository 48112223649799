import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthError } from 'aws-amplify/auth';
import { initFlowbite } from 'flowbite';
import { UserAuth } from '../../models/UserAuth';
import { CognitoService } from '../../services/cognito';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  loginForm: FormGroup;
  public hidePassword = true;
  public errorMessage: string | null = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private cognitoService: CognitoService,
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/,
          ),
        ],
      ],
    });
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  userAuth: UserAuth = {
    username: '',
    userPoolId: '',
  };

  onSubmit() {
    if (this.loginForm.valid) {
      const { email, password } = this.loginForm.value;

      this.cognitoService
        .cognitoSignIn(email, password)
        .then((data) => {
          if (data.isSignedIn === true && data.nextStep.signInStep === 'DONE') {
            this.cognitoService.getAuthDetails().then((authy) => {
              if (authy.tokens) {
                this.userAuth.userPoolId = String(
                  authy.tokens.accessToken.payload['client_id'],
                );
                this.userAuth.username = String(
                  authy.tokens.accessToken.payload['username'],
                );
                this.router.navigate(['/dashboard']).then(() => {
                  initFlowbite();
                });
              }
            });
          }
        })
        .catch((er: unknown) => {
          if (
            er instanceof AuthError &&
            er.name == 'UserAlreadyAuthenticatedException'
          ) {
            this.cognitoService.cognitoSignOut();
          } else if (er instanceof AuthError) {
            this.errorMessage = er.message;
          } else if (er instanceof Error) {
            this.errorMessage = er.message;
          } else {
            this.errorMessage =
              'La connexion a échoué. Veuillez vérifier la connexion et vos identifiants et réessayer.';
          }
        });
    } else {
      this.errorMessage = 'Form is invalid';
    }
  }
}
