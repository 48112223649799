<section
  class="grid grid-cols-12 justify-top gap-8 w-full h-full dark:text-usual-100"
>
  <a
    href="dashboard/mon-compte"
    class="col-span-12 flex flex-row gap-2 items-center text-blue-500 hover:underline"
  >
    <svg
      class="h-6"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <!-- Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
      <path
        d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
      />
    </svg>
    <span>{{ "pages.update-account.return_profile" | translate }}</span>
  </a>

  <div
    class="flex flex-col col-span-12 md:col-span-6 bg-usual-50 dark:bg-usual-800 rounded-2xl p-6 gap-6 relative"
  >
    <h2 class="text-primary-200">
      {{ "pages.update-account.card-1.headline" | translate }}
    </h2>

    <!-- Form -->
    <form
      #updateProfileForm="ngForm"
      (ngSubmit)="updateUserInfo()"
      class="flex flex-col gap-6"
    >
      <div class="flex flex-col md:flex-row gap-6">
        <div class="flex flex-col gap-4 w-full">
          <div class="flex flex-col md:flex-row gap-4">
            <!-- First Name Field -->
            <div class="flex flex-col gap-2 w-full">
              <div
                class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
              >
                <label class="font-bold min-w-max" for="Firstname">{{
                  "pages.update-account.card-1.firstname" | translate
                }}</label>
                <input
                  disabled
                  class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300 disabled:text-usual-400"
                  type="text"
                  id="Firstname"
                  name="Firstname"
                  [(ngModel)]="userInfo.firstName"
                  placeholder="{{
                    'pages.update-account.card-1.firstname_placeholder'
                      | translate
                  }}"
                  required
                />
              </div>
              <span
                class="text-negative-500 dark:text-negative-300 font-bold field-validation-valid"
              ></span>
            </div>

            <!-- Last Name Field -->
            <div class="flex flex-col gap-2 w-full">
              <div
                class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
              >
                <label class="font-bold min-w-max" for="Lastname">{{
                  "pages.update-account.card-1.lastname" | translate
                }}</label>
                <input
                  disabled
                  class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300 disabled:text-usual-400"
                  type="text"
                  id="Lastname"
                  name="Lastname"
                  [(ngModel)]="userInfo.lastName"
                  placeholder="{{
                    'pages.update-account.card-1.lastname_placeholder'
                      | translate
                  }}"
                  required
                />
              </div>
              <span
                class="text-negative-500 dark:text-negative-300 font-bold field-validation-valid"
              ></span>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="flex flex-col gap-6">
        <!-- Email Field -->
        <div class="flex flex-row justify-between gap-2">
          <div
            class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
          >
            <label class="font-bold" for="Email">{{
              "pages.update-account.card-1.email" | translate
            }}</label>
            <input
              class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300 disabled:text-usual-400"
              type="email"
              id="Email"
              name="Email"
              [(ngModel)]="userInfo.email"
              placeholder="{{
                'pages.update-account.card-1.email_placeholder' | translate
              }}"
              disabled
            />
          </div>
          <span
            class="text-negative-500 dark:text-negative-300 font-bold field-validation-valid"
          ></span>
        </div>

        <!-- Phone Number Field -->
        <div class="flex flex-row justify-between gap-2">
          <div
            class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
          >
            <label class="font-bold" for="PhoneNumber">{{
              "pages.update-account.card-1.phone" | translate
            }}</label>
            <input
              class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
              type="tel"
              id="PhoneNumber"
              name="PhoneNumber"
              [(ngModel)]="userInfo.phoneNumber"
              placeholder="{{
                'pages.update-account.card-1.phone_placeholder' | translate
              }}"
              required
            />
          </div>
          <span
            class="text-negative-500 dark:text-negative-300 font-bold field-validation-valid"
          ></span>
        </div>

        <!-- Submit Button -->
        <div class="flex justify-center mt-4">
          <button
            type="submit"
            class="px-4 py-2.5 bg-primary-500 hover:bg-primary-300 dark:bg-primary-300 dark:hover:bg-primary-500 text-usual-50 rounded-lg italic text-center font-bold"
          >
            {{ "pages.update-account.card-1.save_button" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>

  <div
    *ngIf="userInfo.addresses.length !== 0"
    class="flex flex-col col-span-12 md:col-span-6 bg-usual-50 dark:bg-usual-800 rounded-2xl p-6 gap-6 relative"
  >
    <div>
      <h2 class="text-primary-200">
        {{ "pages.update-account.card-2.headline" | translate }}
      </h2>

      <!-- Form -->

      <form
        #updateProfileForm="ngForm"
        (ngSubmit)="updateAddress()"
        [formGroup]="updateUserAdressForm"
        class="flex flex-col gap-6"
      >
        <div class="flex flex-col gap-6">
          <!-- Address Fields -->
          <div *ngIf="userInfo.addresses && userInfo.addresses.length > 0">
            <div class="flex flex-col gap-4">
              <!-- Street Number -->
              <div class="flex flex-row justify-between gap-2">
                <div
                  class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
                >
                  <label class="font-bold" for="streetNumber">
                    {{
                      "pages.update-account.card-2.street_number" | translate
                    }}
                  </label>
                  <input
                    class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
                    type="text"
                    id="streetNumber"
                    name="streetNumber"
                    formControlName="streetNumber"
                    placeholder="{{
                      'pages.update-account.card-2.street_number_placeholder'
                        | translate
                    }}"
                    required
                  />
                </div>
              </div>

              <!-- Street -->
              <div class="flex flex-row justify-between gap-2">
                <div
                  class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
                >
                  <label class="font-bold" for="street">
                    {{ "pages.update-account.card-2.street" | translate }}
                  </label>
                  <input
                    class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
                    type="text"
                    id="street"
                    name="street"
                    formControlName="street"
                    placeholder="{{
                      'pages.update-account.card-2.street_placeholder'
                        | translate
                    }}"
                    required
                  />
                </div>
              </div>

              <!-- Zip Code -->
              <div class="flex flex-row justify-between gap-2">
                <div
                  class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
                >
                  <label class="font-bold min-w-max" for="zipCode">
                    {{ "pages.update-account.card-2.zip_code" | translate }}
                  </label>
                  <input
                    class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
                    type="text"
                    id="zipCode"
                    name="zipCode"
                    formControlName="zipCode"
                    placeholder="{{
                      'pages.update-account.card-2.zip_code_placeholder'
                        | translate
                    }}"
                    required
                  />
                </div>
              </div>

              <!-- City -->
              <div class="flex flex-row justify-between gap-2">
                <div
                  class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
                >
                  <label class="font-bold" for="city">
                    {{ "pages.update-account.card-2.city" | translate }}
                  </label>
                  <input
                    class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
                    type="text"
                    id="city"
                    name="city"
                    formControlName="city"
                    placeholder="{{
                      'pages.update-account.card-2.city_placeholder' | translate
                    }}"
                    required
                  />
                </div>
              </div>

              <!-- Country -->
              <div class="flex flex-row justify-between gap-2">
                <div
                  class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
                >
                  <label class="font-bold" for="country">
                    {{ "pages.update-account.card-2.country" | translate }}
                  </label>
                  <input
                    class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300 disabled:text-usual-400"
                    type="text"
                    id="country"
                    name="country"
                    formControlName="country"
                    placeholder="{{
                      'pages.update-account.card-2.country_placeholder'
                        | translate
                    }}"
                  />
                </div>
              </div>

              <!-- Complement -->
              <div class="flex flex-row justify-between gap-2">
                <div
                  class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
                >
                  <label class="font-bold" for="complement">
                    {{ "pages.update-account.card-2.complement" | translate }}
                  </label>
                  <input
                    class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
                    type="text"
                    id="complement"
                    name="complement"
                    formControlName="complement"
                    placeholder="{{
                      'pages.update-account.card-2.complement_placeholder'
                        | translate
                    }}"
                  />
                </div>
              </div>

              <!-- Region -->
              <div class="flex flex-row justify-between gap-2">
                <div
                  class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
                >
                  <label class="font-bold" for="region">
                    {{ "pages.update-account.card-2.region" | translate }}
                  </label>
                  <input
                    class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
                    type="text"
                    id="region"
                    name="region"
                    formControlName="region"
                    placeholder="{{
                      'pages.update-account.card-2.region_placeholder'
                        | translate
                    }}"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Submit Button -->
          <div class="flex justify-center mt-4">
            <button
              type="submit"
              class="px-4 py-2.5 bg-primary-500 hover:bg-primary-300 dark:bg-primary-300 dark:hover:bg-primary-500 text-usual-50 rounded-lg italic text-center font-bold"
            >
              {{ "pages.update-account.card-2.save_button" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- Toast Alert Message -->
  <div
    *ngIf="isToastVisible"
    [ngClass]="{
      'bg-positive-100 text-positive-500 dark:bg-positive-800 dark:text-positive-200':
        toastType === 'success',
      'bg-negative-100 text-negative-500 dark:bg-negative-800 dark:text-negative-200':
        toastType === 'danger',
    }"
    class="flex fixed right-4 bottom-0 items-center w-full max-w-xs p-4 mb-4 text-usual-500 rounded-lg shadow dark:text-usual-400 dark:bg-usual-800"
    role="alert"
  >
    <div
      class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8"
      [ngClass]="{
        'text-positive-500 bg-positive-100 dark:bg-positive-800 dark:text-positive-200':
          toastType === 'success',
        'text-negative-500 bg-negative-100 dark:bg-negative-800 dark:text-negative-200':
          toastType === 'danger',
      }"
    >
      <svg
        class="w-5 h-5"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          [attr.d]="
            toastType === 'success'
              ? 'M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z'
              : 'M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z'
          "
        />
      </svg>
      <span class="sr-only">
        {{ toastType === "success" ? "Check icon" : "Error icon" }}
      </span>
    </div>
    <div class="ms-3 text-sm font-normal">
      {{ toastMessage }}
    </div>
    <button
      type="button"
      class="ms-auto -mx-1.5 -my-1.5 bg-usual-50 text-usual-400 hover:text-usual-900 rounded-lg focus:ring-2 focus:ring-usual-300 p-1.5 hover:bg-usual-100 inline-flex items-center justify-center h-8 w-8 dark:text-usual-500 dark:hover:text-usual-50 dark:bg-usual-800 dark:hover:bg-usual-700"
      data-dismiss-target="#toast-success"
      aria-label="Close"
    >
      <span class="sr-only">Close</span>
      <svg
        class="w-3 h-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
    </button>
  </div>
</section>
