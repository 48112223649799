import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-assistance',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './assistance.component.html',
  styleUrl: './assistance.component.scss',
})
export class AssistanceComponent {
  isQuestionOpen: boolean[] = Array(9).fill(false);

  toggleQuestion(index: number): void {
    this.isQuestionOpen[index] = !this.isQuestionOpen[index];
  }
}
