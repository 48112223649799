import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Address } from '../models/Address';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private API_URL = environment.API_URL;
  constructor(private http: HttpClient) {}

  createAddress(addressPayload: Address): Observable<Address> {
    return this.http.post<Address>(
      `${this.API_URL}v1/addresses/create`,
      addressPayload,
    );
  }

  getAddress(addressId: number): Observable<Address> {
    return this.http.get<Address>(`${this.API_URL}v1/addresses/${addressId}`);
  }

  updateAddress(addressPayload: Address): Observable<Address> {
    return this.http.put<Address>(
      `${this.API_URL}v1/addresses/update`,
      addressPayload,
    );
  }
}
