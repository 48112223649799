<section
  class="flex flex-col justify-between h-screen bg-[url('/assets/imgs/bg_auth.png')] bg-no-repeat bg-right bg-contain"
>
  <div class="py-20 px-4 md:px-8 flex flex-col gap-10">
    <div class="max-w-md md:max-w-2xl mx-auto flex flex-col gap-8">
      <div class="flex flex-col gap-6 items-center justify-center text-center">
        <img
          class="h-32"
          src="../../../assets/logo/efusion-icon.png"
          alt="Logo d'e-Fusion"
        />
        <h2>{{ "authentication.reset-password.headline" | translate }}</h2>
      </div>
      <form
        [formGroup]="resetpasswordForm"
        (ngSubmit)="onSubmit()"
        class="space-y-6"
      >
        <div class="flex flex-col gap-2 w-full">
          <!-- Email Field Start -->
          <div
            class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
          >
            <label class="font-bold min-w-max" for="email"
              >{{
                "authentication.reset-password.email_label" | translate
              }}
              *</label
            >
            <input
              formControlName="email"
              type="email"
              id="email"
              name="email"
              placeholder="{{
                'authentication.reset-password.email_placeholder' | translate
              }}"
              required
              email
              class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
            />
          </div>
          <span
            class="text-negative-500 dark:text-negative-300 font-bold"
            *ngIf="
              resetpasswordForm.get('email')?.touched &&
              resetpasswordForm.get('email')?.errors?.['required']
            "
            >{{
              "authentication.reset-password.email_required_error" | translate
            }}</span
          >
          <span
            class="text-negative-500 dark:text-negative-300 font-bold"
            *ngIf="
              resetpasswordForm.get('email')?.touched &&
              resetpasswordForm.get('email')?.errors?.['email']
            "
            >{{
              "authentication.reset-password.email_syntax_error" | translate
            }}</span
          >
        </div>
        <!-- Email Field End -->
        <div class="flex flex-col gap-4 items-center justify-center">
          <div class="flex flex-col gap-2 items-center">
            <button
              type="submit"
              class="bg-primary-500 hover:bg-primary-300 dark:bg-primary-300 dark:hover:bg-primary-500 text-usual-50"
            >
              {{
                "authentication.reset-password.button_reset_password"
                  | translate
              }}
            </button>
            <p class="text-usual-600 text-xs">
              *
              {{
                "authentication.reset-password.required_field_text" | translate
              }}
            </p>
          </div>
          <a
            class="text-primary-500 dark:text-primary-300 hover:text-primary-300 dark:hover:text-primary-500 underline font-bold italic px-4 py-2.2"
            href="/auth/login"
            >{{
              "authentication.reset-password.return_login_screen" | translate
            }}</a
          >
        </div>
      </form>
    </div>
  </div>
  <!-- Verification Code & New Password Modal -->
  <div
    *ngIf="showModal"
    id="authentication-modal"
    tabindex="-1"
    aria-hidden="true"
    class="fixed inset-0 z-50 overflow-y-auto flex justify-center items-center h-screen bg-black bg-opacity-50"
  >
    <div class="relative p-4 w-full max-w-md max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div
          class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600"
        >
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
            {{ "authentication.reset-password.modal_headline" | translate }}
          </h3>
          <button
            type="button"
            class="end-2.5 text-primary-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            (click)="closeModal()"
          >
            <svg
              class="flex-shrink-0 w-5 h-5 text-usual-900 transition duration-75 dark:text-usual-50 group-hover:text-usual-900 dark:group-hover:text-primary-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 384 512"
            >
              <path
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              />
            </svg>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-4 md:p-5">
          <form
            [formGroup]="verificationForm"
            (ngSubmit)="onSubmitCode()"
            class="flex flex-col gap-4 max-w-sm mx-auto"
          >
            <div class="flex justify-center mb-2 space-x-2 rtl:space-x-reverse">
              <div>
                <label for="code1" class="sr-only">First code</label>
                <input
                  formControlName="code1"
                  type="number"
                  maxlength="1"
                  id="code-1"
                  class="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  required
                />
              </div>
              <div>
                <label for="code2" class="sr-only">Second code</label>
                <input
                  formControlName="code2"
                  type="number"
                  maxlength="1"
                  id="code-2"
                  class="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  required
                />
              </div>
              <div>
                <label for="code3" class="sr-only">Third code</label>
                <input
                  formControlName="code3"
                  type="number"
                  maxlength="1"
                  id="code-3"
                  class="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  required
                />
              </div>
              <div>
                <label for="code4" class="sr-only">Fourth code</label>
                <input
                  formControlName="code4"
                  type="number"
                  maxlength="1"
                  id="code-4"
                  class="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  required
                />
              </div>
              <div>
                <label for="code5" class="sr-only">Fifth code</label>
                <input
                  formControlName="code5"
                  type="number"
                  maxlength="1"
                  id="code-5"
                  class="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  required
                />
              </div>
              <div>
                <label for="code6" class="sr-only">Sixth code</label>
                <input
                  formControlName="code6"
                  type="number"
                  maxlength="1"
                  id="code-6"
                  class="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  required
                />
              </div>
            </div>
            <p
              id="helper-text-explanation"
              class="mt-2 text-sm text-center text-gray-500 dark:text-gray-400"
            >
              {{
                "authentication.reset-password.modal_text_explanation"
                  | translate
              }}
            </p>
            <div class="flex flex-col gap-2 w-full">
              <!-- New Password Field Start -->
              <div
                class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
              >
                <label class="font-bold min-w-max" for="newpassword"
                  >{{
                    "authentication.reset-password.modal_new_password_label"
                      | translate
                  }}
                  *</label
                >
                <input
                  matInput
                  type="newpassword"
                  formControlName="newpassword"
                  autocomplete="newpassword"
                  placeholder="********"
                  [type]="hidePassword ? 'newpassword' : 'text'"
                  required
                  class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
                />
                <mat-icon
                  matSuffix
                  class="icon-clickable min-w-max"
                  (click)="hidePassword = !hidePassword"
                  >{{
                    hidePassword ? "visibility" : "visibility_off"
                  }}</mat-icon
                >
              </div>
              <mat-error
                *ngIf="
                  verificationForm.get('newpassword')?.invalid &&
                  verificationForm.get('newpassword')?.touched
                "
              >
                <span
                  class="text-negative-500 dark:text-negative-300 font-bold"
                  *ngIf="
                    verificationForm.get('newpassword')?.errors?.['required']
                  "
                  >{{
                    "authentication.reset-password.modal_new_password_required_error"
                      | translate
                  }}</span
                >
                <span
                  class="text-negative-500 dark:text-negative-300 font-bold"
                  *ngIf="
                    verificationForm.get('newpassword')?.errors?.[
                      'invalidPassword'
                    ]
                  "
                  >{{
                    "authentication.reset-password.modal_new_password_syntax_error"
                      | translate
                  }}</span
                >
              </mat-error>
            </div>
            <!-- New Password Field End -->
            <button
              type="submit"
              class="w-full bg-primary-500 hover:bg-primary-300 dark:bg-primary-300 dark:hover:bg-primary-500 text-usual-50"
            >
              {{
                "authentication.reset-password.modal_button_submit" | translate
              }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
