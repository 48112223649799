import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { UserAuth } from '../models/UserAuth';
import { CognitoService } from '../services/cognito';

@Injectable({
  providedIn: 'root',
})
//note the CanActivate isn't deprecated
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private cognitoService: CognitoService,
  ) {}

  authVerfied: UserAuth = {
    username: '',
    userPoolId: '',
  };

  async verifyLogIn() {
    await this.cognitoService.getAuthDetails().then((authy) => {
      if (authy.tokens) {
        this.authVerfied.userPoolId = String(
          authy.tokens.accessToken.payload['client_id'],
        );
        this.authVerfied.username = String(
          authy.tokens.accessToken.payload['username'],
        );
      } else {
        this.authVerfied.userPoolId = '';
        this.authVerfied.username = '';
      }
    });
  }

  async canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    await this.verifyLogIn();

    if (
      !this.authVerfied.userPoolId &&
      !this.authVerfied.username &&
      state.url.startsWith('/dashboard')
    ) {
      return this.router.createUrlTree(['/auth/login']);
    }

    if (
      this.authVerfied.userPoolId &&
      this.authVerfied.userPoolId !== '' &&
      this.authVerfied.username &&
      this.authVerfied.username !== '' &&
      state.url.startsWith('/auth')
    ) {
      return this.router.createUrlTree(['/dashboard']);
    }

    return true;
  }
}
