import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './authentication/auth.guard';
import { LoginComponent } from './authentication/login/login.component';
import { RegisterComponent } from './authentication/register/register.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AccountComponent } from './pages/account/account.component';
import { AssistanceComponent } from './pages/assistance/assistance.component';
import { ConsumptionComponent } from './pages/consumption/consumption.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { RechargeComponent } from './pages/recharge/recharge.component';
import { UpdateAccountComponent } from './pages/update-account/update-account.component';
import { UpdatePasswordComponent } from './pages/update-password/update-password.component';
import { SidebarComponent } from './sidebar/sidebar.component';

export const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: SidebarComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        title: 'Dashboard | e-Vision',
      },
      {
        path: 'consumption',
        component: ConsumptionComponent,
        title: 'Consumption | e-Vision',
      },
      {
        path: 'invoices',
        component: InvoicesComponent,
        title: 'Invoices | e-Vision',
      },
      {
        path: 'assistance',
        component: AssistanceComponent,
        title: 'Assistance | e-Vision',
      },
      {
        path: 'recharger',
        component: RechargeComponent,
        title: 'Recharger | e-Vision',
      },
      {
        path: 'mon-compte',
        component: AccountComponent,
        title: 'Mon compte | e-Vision',
      },
      {
        path: 'update-password',
        component: UpdatePasswordComponent,
        title: 'Changer le mot de passe | e-Vision',
      },
      {
        path: 'update-account',
        component: UpdateAccountComponent,
        title: 'Modifier le compte | e-Vision',
      },
    ],
  },
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'resetpassword', component: ResetPasswordComponent },
    ],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
