<section
  class="drop-shadow-lg dark:text-usual-100 border-usual-200 dark:border-usual-700 rounded-2xl flex flex-col gap-6"
>
  <div class="grid grid-cols-4 md:grid-cols-12 gap-6 items-center">
    <div
      class="col-span-4 h-full p-6 bg-usual-50 dark:bg-usual-900 rounded-2xl shadow-lg border-usual-200 dark:border-usual-700 gap-2 relative overflow-hidden"
    >
      <!-- Invoice -->
      <div class="flex items-center justify-between">
        <h3>{{ "pages.dashboard.invoices.headline" | translate }}</h3>
        <a
          class="text-primary-500 hover:text-primary-300 dark:text-primary-300 dark:hover:text-primary-500 underline italic font-bold"
          href="dashboard/invoices"
          >{{ "pages.dashboard.invoices.navigate_page" | translate }}</a
        >
      </div>
      <div class="flex flex-col gap-1 z-10 relative">
        <p class="text-usual-600 dark:text-usual-400">
          {{ "pages.dashboard.invoices.subheadline" | translate }}
        </p>
        <div class="flex items-center space-x-2">
          <h2 class="text-primary-300">20 €</h2>
          <h3 class="text-primary-300">TTC</h3>
        </div>
      </div>
      <svg
        class="svg-inline--fa fa-file-invoice-dollar absolute justify-right h-20 -bottom-6 right-4 text-usual-100 dark:text-usual-800"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="file-invoice-dollar"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 384 512"
        data-fa-i2svg=""
      >
        <path
          fill="currentColor"
          d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 80c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm128 72c8.8 0 16 7.2 16 16v17.3c8.5 1.2 16.7 3.1 24.1 5.1c8.5 2.3 13.6 11 11.3 19.6s-11 13.6-19.6 11.3c-11.1-3-22-5.2-32.1-5.3c-8.4-.1-17.4 1.8-23.6 5.5c-5.7 3.4-8.1 7.3-8.1 12.8c0 3.7 1.3 6.5 7.3 10.1c6.9 4.1 16.6 7.1 29.2 10.9l.5 .1 0 0 0 0c11.3 3.4 25.3 7.6 36.3 14.6c12.1 7.6 22.4 19.7 22.7 38.2c.3 19.3-9.6 33.3-22.9 41.6c-7.7 4.8-16.4 7.6-25.1 9.1V440c0 8.8-7.2 16-16 16s-16-7.2-16-16V422.2c-11.2-2.1-21.7-5.7-30.9-8.9l0 0c-2.1-.7-4.2-1.4-6.2-2.1c-8.4-2.8-12.9-11.9-10.1-20.2s11.9-12.9 20.2-10.1c2.5 .8 4.8 1.6 7.1 2.4l0 0 0 0 0 0c13.6 4.6 24.6 8.4 36.3 8.7c9.1 .3 17.9-1.7 23.7-5.3c5.1-3.2 7.9-7.3 7.8-14c-.1-4.6-1.8-7.8-7.7-11.6c-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5 0 0c-11-3.3-24.3-7.3-34.8-13.7c-12-7.2-22.6-18.9-22.7-37.3c-.1-19.4 10.8-32.8 23.8-40.5c7.5-4.4 15.8-7.2 24.1-8.7V232c0-8.8 7.2-16 16-16z"
        ></path>
      </svg>
    </div>

    <div
      class="col-span-4 h-full p-6 bg-usual-50 dark:bg-usual-900 shadow-lg border-usual-200 dark:border-usual-700 rounded-2xl gap-2 relative overflow-hidden"
    >
      <!-- Subscription -->
      <div class="flex items-center justify-between">
        <h3>{{ "pages.dashboard.subscription.headline" | translate }}</h3>
        <a
          href="dashboard/mon-compte"
          class="text-primary-500 hover:text-primary-300 dark:text-primary-300 dark:hover:text-primary-500 underline italic font-bold"
          >{{ "pages.dashboard.subscription.navigate_page" | translate }}</a
        >
      </div>
      <div class="flex flex-col gap-1 z-10 relative">
        <p class="text-usual-600 dark:text-usual-400">
          {{ "pages.dashboard.subscription.subheadline" | translate }}
        </p>
        <div class="flex flex-col justify-center items-center">
          <p class="text-primary-300">
            {{ "pages.dashboard.subscription.card_number" | translate }}
          </p>
          <p class="text-primary-300">E7196FFE</p>
        </div>
      </div>
      <svg
        class="svg-inline--fa fa-credit-card absolute justify-right h-20 -bottom-6 right-4 text-usual-100 dark:text-usual-800"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="credit-card"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
        data-fa-i2svg=""
      >
        <path
          fill="currentColor"
          d="M64 32C28.7 32 0 60.7 0 96v32H576V96c0-35.3-28.7-64-64-64H64zM576 224H0V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V224zM112 352h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16z"
        ></path>
      </svg>
    </div>

    <div
      class="col-span-4 p-6 bg-usual-50 dark:bg-usual-900 shadow-lg border-usual-200 dark:border-usual-700 rounded-2xl gap-2 relative overflow-hidden"
    >
      <!-- Car -->
      <div class="flex items-center justify-between">
        <h3>{{ "pages.dashboard.vehicules.headline" | translate }}</h3>
        <a
          class="text-primary-500 hover:text-primary-300 dark:text-primary-300 dark:hover:text-primary-500 underline italic font-bold"
          href="dashboard/mon-compte"
          >{{ "pages.dashboard.vehicules.navigate_page" | translate }}</a
        >
      </div>
      <div class="flex flex-col gap-1 z-10 relative">
        <p class="text-usual-600 dark:text-usual-400">
          {{ "pages.dashboard.vehicules.subheadline" | translate }}
        </p>
        <div class="flex flex-row justify-center items-center gap-8">
          <div class="flex flex-col justify-center items-center">
            <p class="text-primary-300">4</p>
            <p class="text-primary-300">
              {{ "pages.dashboard.vehicules.vehicule_number" | translate }}
            </p>
          </div>
          <div class="flex h-16 border-r-2 border-usual-300"></div>
          <div class="flex flex-col justify-center items-center">
            <p class="text-primary-300 text-center">
              {{ "pages.dashboard.vehicules.car_license" | translate }}
            </p>
            <p class="text-primary-300">GB-104-ZL</p>
          </div>
        </div>
      </div>
      <svg
        class="absolute justify-right h-20 -bottom-6 right-4 text-usual-100 dark:text-usual-800"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
        <path
          d="M135.2 117.4L109.1 192l293.8 0-26.1-74.6C372.3 104.6 360.2 96 346.6 96L165.4 96c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32l181.2 0c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2l0 144 0 48c0 17.7-14.3 32-32 32l-32 0c-17.7 0-32-14.3-32-32l0-48L96 400l0 48c0 17.7-14.3 32-32 32l-32 0c-17.7 0-32-14.3-32-32l0-48L0 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
        />
      </svg>
    </div>
  </div>

  <div
    class="p-6 bg-usual-50 dark:bg-usual-900 shadow-lg border-usual-200 dark:border-usual-700 pt-4 space-y-2 rounded-2xl relative overflow-hidden"
  >
    <div class="flex justify-between items-center">
      <h3>{{ "pages.dashboard.consumption.headline" | translate }}</h3>
      <a
        class="text-primary-500 hover:text-primary-300 dark:text-primary-300 dark:hover:text-primary-500 underline italic font-bold"
        href="/dashboard/consumption"
        >{{ "pages.dashboard.consumption.navigate_page" | translate }}</a
      >
    </div>
    <div>
      <p class="text-usual-600 dark:text-usual-400">
        {{ "pages.dashboard.consumption.current_year" | translate }}
        {{ currentYear }}
      </p>
      <div class="flex items-center space-x-2">
        <p class="font-bold">
          {{ "pages.dashboard.consumption.current_month" | translate }} :
        </p>
        <p class="text-primary-500 font-bold">{{ currentMonthData }} kWh / h</p>
      </div>
    </div>
    <div class="flex flex-col gap-1 z-10 relative h-80 md:h-full w-full">
      <canvas id="MyChart"></canvas>
    </div>
    <svg
      class="absolute justify-right h-20 -bottom-6 right-4 text-usual-100 dark:text-usual-800"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
      <path
        d="M160 80c0-26.5 21.5-48 48-48l32 0c26.5 0 48 21.5 48 48l0 352c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48l0-352zM0 272c0-26.5 21.5-48 48-48l32 0c26.5 0 48 21.5 48 48l0 160c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48L0 272zM368 96l32 0c26.5 0 48 21.5 48 48l0 288c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48z"
      />
    </svg>
  </div>
</section>
