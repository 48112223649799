import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Address } from '../../models/Address';
import { User } from '../../models/User';
import { UserAuth } from '../../models/UserAuth';
import { UserService } from '../../services/UserService/user.service';
import { AddressService } from '../../services/address.service';
import { CognitoService } from '../../services/cognito';

@Component({
  selector: 'app-update-account',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
  ],
  templateUrl: './update-account.component.html',
  styleUrl: './update-account.component.scss',
})
export class UpdateAccountComponent implements OnInit {
  userInfo: User = {
    username: '',
    email: '',
    addresses: [
      {
        id: 0,
        city: '',
        streetNumber: 0,
        street: '',
        complement: '',
        zipCode: '',
        region: '',
        country: '',
        type: '',
      },
    ],
    vehicules: [],
    phoneNumber: '',
    userPoolId: '',
    firstName: '',
    lastName: '',
    enabled: false,
    rfididentification: {
      uID: '',
    },
    birthDate: '',
    hasPaymentMethod: false,
    stripeCustomerId: '',
    currentChargeSessionSessionId: null,
  };
  updateUserInfoForm: FormGroup;
  updateUserAdressForm: FormGroup;
  isToastVisible = false;
  toastType: 'success' | 'danger' = 'success';
  toastMessage = '';

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private cognitoService: CognitoService,
    private addressService: AddressService,
    private translate: TranslateService,
  ) {
    this.updateUserInfoForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.updateUserAdressForm = this.fb.group({
      city: ['', Validators.required],
      streetNumber: ['', Validators.required],
      street: ['', Validators.required],
      complement: [''],
      zipCode: ['', Validators.required],
      region: [''],
      country: [{ value: '', disabled: true }],
    });
  }

  ngOnInit(): void {
    this.verifyLogIn().then(
      () => {
        this.getUserDetails(
          this.authVerfied.userPoolId,
          this.authVerfied.username,
        );
      },
      (error) => {
        console.log(error);
      },
    );
  }

  async verifyLogIn() {
    await this.cognitoService.getAuthDetails().then((authy) => {
      if (authy.tokens) {
        const str = String(authy.tokens.accessToken.payload['iss']);
        const lastIndex: number = str.lastIndexOf('/');
        const rel: string = str.substring(lastIndex + 1);
        this.authVerfied.userPoolId = rel;
        this.authVerfied.username = String(
          authy.tokens.accessToken.payload['username'],
        );
      } else {
        this.authVerfied.userPoolId = '';
        this.authVerfied.username = '';
      }
    });
  }

  authVerfied: UserAuth = {
    username: '',
    userPoolId: '',
  };

  rechargeUser?: User;

  private async getUserDetails(userPoolId: string, username: string) {
    this.userService
      .getUserInformations(userPoolId, username)
      .subscribe((data) => {
        const rechargeUser = {
          firstName: data.firstName,
          lastName: data.lastName,
          username: data.username,
          userPoolId: data.userPoolId,
          email: data.email,
          phoneNumber: data.phoneNumber,
          birthDate: data.birthDate,
          enabled: data.enabled,
          stripeCustomerId: data.stripeCustomerId,
          addresses: data.addresses,
          vehicules: data.vehicules,
          rfididentification: data.rfididentification,
          id: data.id,
          hasPaymentMethod: data.hasPaymentMethod,
          currentChargeSessionSessionId: data.currentChargeSessionSessionId,
        };
        this.rechargeUser = rechargeUser;
        this.userInfo = rechargeUser;
        this.updateUserAdressForm.patchValue(rechargeUser.addresses[0]);
      });
  }

  getUserInformation(userPoolId: string, username: string) {
    this.userService.getUserInformations(userPoolId, username).subscribe({
      next: (data) => {
        this.userInfo = data;
      },
      error: (error) => {
        console.error(
          'Erreur lors de la récupération des informations utilisateur :',
          error,
        );
      },
    });
  }

  updateUserInfo() {
    this.userService.updateUserInfo(this.userInfo).subscribe(
      (data: User) => {
        this.userInfo = data;
        this.toastType = 'success';
        this.translate
          .get('pages.update-account.card-1.toast_success_message')
          .subscribe((translated: string) => {
            this.toastMessage = translated;
            this.isToastVisible = true;
            setTimeout(() => (this.isToastVisible = false), 3000);
          });
      },
      () => {
        this.toastType = 'danger';
        this.translate
          .get('pages.update-account.card-1.toast_success_message')
          .subscribe((translated: string) => {
            this.toastMessage = translated;
            this.isToastVisible = true;
            setTimeout(() => (this.isToastVisible = false), 3000);
          });
      },
    );
  }

  updateAddress() {
    const nwAddress: Address = this.updateUserAdressForm.value;
    nwAddress.type = 'BILLING';
    nwAddress.country = 'FRANCE';
    nwAddress.userId = this.rechargeUser?.id;
    nwAddress.id = this.rechargeUser?.addresses[0].id;
    this.addressService.updateAddress(nwAddress).subscribe({
      next: (add: Address) => {
        String(add);
        this.toastType = 'success';
        this.translate
          .get('pages.update-account.card-2.toast_success_message')
          .subscribe((translated: string) => {
            this.toastMessage = translated;
            this.isToastVisible = true;
            setTimeout(() => (this.isToastVisible = false), 3000);
          });
      },
      error: () => {
        this.toastType = 'danger';
        this.translate
          .get('pages.update-account.card-2.toast_success_message')
          .subscribe((translated: string) => {
            this.toastMessage = translated;
            this.isToastVisible = true;
            setTimeout(() => (this.isToastVisible = false), 3000);
          });
      },
    });
  }
}
