<nav
  class="fixed top-0 z-50 w-full bg-usual-50 border-b border-usual-200 dark:bg-usual-900 dark:border-usual-700"
>
  <div class="px-3 py-3 lg:px-5 lg:pl-3">
    <div class="flex items-center justify-between">
      <div class="flex items-center justify-start rtl:justify-end">
        <button
          data-drawer-target="logo-sidebar"
          data-drawer-toggle="logo-sidebar"
          aria-controls="logo-sidebar"
          type="button"
          class="inline-flex items-center p-2 text-sm text-usual-950 rounded-lg sm:hidden hover:bg-usual-100 focus:outline-none focus:ring-2 focus:ring-usual-200 dark:text-usual-400 dark:hover:bg-usual-700 dark:focus:ring-usual-600"
        >
          <span class="sr-only">Open sidebar</span>
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              fill-rule="evenodd"
              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
            ></path>
          </svg>
        </button>
        <a href="dashboard" class="flex ms-2 md:me-24">
          <img
            src="../../assets/logo/efusion-icon.png"
            class="h-8 me-3"
            alt="eFusion Logo"
          />
          <span
            class="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-usual-50"
            >e-Vision</span
          >
        </a>
      </div>
      <div class="flex items-center">
        <div class="flex items-center ms-3">
          <div>
            <button
              type="button"
              class="flex px-4 text-sm bg-usual-50 dark:bg-usual-800 rounded-full focus:ring-4 focus:ring-usual-300 dark:focus:ring-usual-600"
              aria-expanded="false"
              data-dropdown-toggle="dropdown-user"
            >
              <span class="sr-only">Open user menu</span>
              <svg
                class="h-4 dark:text-usual-100"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill="currentColor"
              >
                <path
                  d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z"
                />
              </svg>
            </button>
          </div>
          <div
            class="z-50 hidden my-4 text-base list-none bg-usual-50 divide-y divide-usual-100 rounded shadow dark:bg-usual-700 dark:divide-usual-600"
            id="dropdown-user"
          >
            <div class="px-4 py-3" role="none">
              <p class="text-sm text-usual-900 dark:text-usual-50" role="none">
                {{ rechargeUser?.firstName + " " + rechargeUser?.lastName }}
              </p>
            </div>
            <ul class="py-1" role="none">
              <li>
                <a
                  href="dashboard/mon-compte"
                  class="block px-4 py-2 text-sm text-usual-700 hover:bg-usual-100 dark:text-usual-300 dark:hover:bg-usual-600 dark:hover:text-usual-50"
                  role="menuitem"
                  >{{ "sidebar.profil" | translate }}</a
                >
              </li>
              <li>
                <button
                  (click)="signOut()"
                  class="block px-4 py-2 text-sm text-usual-700 hover:bg-usual-100 dark:text-usual-300 dark:hover:bg-usual-600 dark:hover:text-usual-50"
                  role="menuitem"
                >
                  {{ "sidebar.button_signout" | translate }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<aside
  id="logo-sidebar"
  class="fixed top-0 left-0 z-40 w-64 h-screen pt-24 transition-transform -translate-x-full bg-usual-50 border-r border-usual-200 sm:translate-x-0 dark:bg-usual-800 dark:border-usual-700"
  aria-label="Sidebar"
>
  <div class="h-full px-3 pb-4 overflow-y-auto bg-usual-50 dark:bg-usual-800">
    <ul class="space-y-8 font-medium">
      <li>
        <a
          href="dashboard"
          class="flex items-center p-2 text-usual-900 rounded-lg dark:text-usual-50 hover:bg-usual-100 dark:hover:bg-usual-700 group"
        >
          <svg
            class="flex-shrink-0 w-5 h-5 text-primary-200 transition duration-75 dark:text-primary-400 group-hover:text-usual-900 dark:group-hover:text-primary-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 18"
          >
            <path
              d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"
            />
          </svg>
          <span class="ms-3">{{ "sidebar.dashboard" | translate }}</span>
        </a>
      </li>
      <li>
        <a
          href="dashboard/consumption"
          class="flex items-center p-2 text-usual-900 rounded-lg dark:text-usual-50 hover:bg-usual-100 dark:hover:bg-usual-700 group"
        >
          <svg
            class="w-5 h-5 text-primary-200 transition duration-75 dark:text-primary-400 group-hover:text-usual-900 dark:group-hover:text-primary-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 448 512"
          >
            <path
              d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z"
            />
          </svg>
          <span class="ms-3">{{ "sidebar.consumption" | translate }}</span>
        </a>
      </li>
      <li>
        <a
          href="dashboard/invoices"
          class="flex items-center p-2 text-usual-900 rounded-lg dark:text-usual-50 hover:bg-usual-100 dark:hover:bg-usual-700 group"
        >
          <svg
            class="flex-shrink-0 w-5 h-5 text-primary-200 transition duration-75 dark:text-primary-400 group-hover:text-usual-900 dark:group-hover:text-primary-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 384 512"
          >
            <path
              d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 80c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm128 72c8.8 0 16 7.2 16 16v17.3c8.5 1.2 16.7 3.1 24.1 5.1c8.5 2.3 13.6 11 11.3 19.6s-11 13.6-19.6 11.3c-11.1-3-22-5.2-32.1-5.3c-8.4-.1-17.4 1.8-23.6 5.5c-5.7 3.4-8.1 7.3-8.1 12.8c0 3.7 1.3 6.5 7.3 10.1c6.9 4.1 16.6 7.1 29.2 10.9l.5 .1 0 0 0 0c11.3 3.4 25.3 7.6 36.3 14.6c12.1 7.6 22.4 19.7 22.7 38.2c.3 19.3-9.6 33.3-22.9 41.6c-7.7 4.8-16.4 7.6-25.1 9.1V440c0 8.8-7.2 16-16 16s-16-7.2-16-16V422.2c-11.2-2.1-21.7-5.7-30.9-8.9l0 0 0 0c-2.1-.7-4.2-1.4-6.2-2.1c-8.4-2.8-12.9-11.9-10.1-20.2s11.9-12.9 20.2-10.1c2.5 .8 4.8 1.6 7.1 2.4l0 0 0 0 0 0c13.6 4.6 24.6 8.4 36.3 8.7c9.1 .3 17.9-1.7 23.7-5.3c5.1-3.2 7.9-7.3 7.8-14c-.1-4.6-1.8-7.8-7.7-11.6c-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5 0 0c-11-3.3-24.3-7.3-34.8-13.7c-12-7.2-22.6-18.9-22.7-37.3c-.1-19.4 10.8-32.8 23.8-40.5c7.5-4.4 15.8-7.2 24.1-8.7V232c0-8.8 7.2-16 16-16z"
            />
          </svg>
          <span class="ms-3">{{ "sidebar.invoices" | translate }}</span>
        </a>
      </li>
      <li>
        <a
          href="dashboard/recharger"
          class="flex items-center p-2 text-usual-900 rounded-lg dark:text-usual-50 hover:bg-usual-100 dark:hover:bg-usual-700 group"
        >
          <svg
            class="flex-shrink-0 w-5 h-5 text-primary-200 transition duration-75 dark:text-primary-400 group-hover:text-usual-900 dark:group-hover:text-primary-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 576 512"
          >
            <path
              d="M96 0C60.7 0 32 28.7 32 64V448c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32s-14.3-32-32-32V304h16c22.1 0 40 17.9 40 40v32c0 39.8 32.2 72 72 72s72-32.2 72-72V252.3c32.5-10.2 56-40.5 56-76.3V144c0-8.8-7.2-16-16-16H544V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H480V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H432c-8.8 0-16 7.2-16 16v32c0 35.8 23.5 66.1 56 76.3V376c0 13.3-10.7 24-24 24s-24-10.7-24-24V344c0-48.6-39.4-88-88-88H320V64c0-35.3-28.7-64-64-64H96zM216.9 82.7c6 4 8.5 11.5 6.3 18.3l-25 74.9H256c6.7 0 12.7 4.2 15 10.4s.5 13.3-4.6 17.7l-112 96c-5.5 4.7-13.4 5.1-19.3 1.1s-8.5-11.5-6.3-18.3l25-74.9H96c-6.7 0-12.7-4.2-15-10.4s-.5-13.3 4.6-17.7l112-96c5.5-4.7 13.4-5.1 19.3-1.1z"
            />
          </svg>
          <span class="ms-3">{{ "sidebar.recharge" | translate }}</span>
        </a>
      </li>
      <li>
        <a
          href="dashboard/assistance"
          class="flex items-center p-2 text-usual-900 rounded-lg dark:text-usual-50 hover:bg-usual-100 dark:hover:bg-usual-700 group"
        >
          <svg
            class="flex-shrink-0 w-5 h-5 text-primary-200 transition duration-75 dark:text-primary-400 group-hover:text-usual-900 dark:group-hover:text-primary-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
            />
          </svg>
          <span class="ms-3">{{ "sidebar.assistance" | translate }}</span>
        </a>
      </li>
    </ul>
    <div
      class="flex flex-row justify-center gap-2 absolute bottom-0 left-0 w-full p-4"
    >
      <div class="relative w-full inline-block text-left">
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 text-sm font-medium text-usual-900 dark:text-usual-50 bg-gray-100 dark:bg-usual-700 border border-gray-300 rounded-lg shadow-sm hover:bg-gray-200 dark:hover:bg-usual-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          data-dropdown-toggle="language-dropdown"
        >
          {{ "sidebar.language" | translate }}
          <svg
            class="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        <div
          id="language-dropdown"
          class="hidden absolute bg-white dark:bg-usual-800 border border-gray-200 dark:border-usual-700 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <ul class="py-1">
            <li>
              <button
                type="button"
                (click)="switchLanguage('en')"
                class="block px-4 py-2 text-sm text-usual-900 dark:text-usual-50 hover:bg-gray-100 dark:hover:bg-usual-600"
              >
                {{ "sidebar.itemdropdown_english" | translate }}
              </button>
            </li>
            <li>
              <button
                type="button"
                (click)="switchLanguage('fr')"
                class="block px-4 py-2 text-sm text-usual-900 dark:text-usual-50 hover:bg-gray-100 dark:hover:bg-usual-600"
              >
                {{ "sidebar.itemdropdown_french" | translate }}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <label for="themeToggle" class="flex items-center cursor-pointer">
        <!-- <span class="mr-2 text-sm dark:text-usual-50">
          {{ "sidebar.toggle_theme" | translate }}
        </span> -->
        <input
          id="themeToggle"
          type="checkbox"
          (change)="toggleTheme($event)"
          class="hidden"
        />
        <div class="relative">
          <div class="w-12 h-6 bg-gray-200 rounded-full shadow-inner"></div>
          <div
            class="flex flex-row justify-center items-center absolute top-0 w-6 h-6 rounded-full shadow inset-y-0 left-0 transform transition-transform duration-300 ease-in-out"
            [class.translate-x-6]="isDarkMode"
          >
            <!-- Icon for light mode -->
            <ng-container *ngIf="!isDarkMode">
              <svg
                class="w-4 h-4 text-yellow-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                fill="currentColor"
              >
                <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                <path
                  d="M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z"
                />
              </svg>
            </ng-container>

            <!-- Icon for dark mode -->
            <ng-container *ngIf="isDarkMode">
              <svg
                class="w-4 h-4 text-primary-800"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                fill="currentColor"
              >
                <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                <path
                  d="M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"
                />
              </svg>
            </ng-container>
          </div>
        </div>
      </label>
    </div>
  </div>
</aside>

<div
  class="px-4 min-h-full md:px-16 py-14 relative mt-10 md:mt-16 bg-usual-100 dark:bg-usual-700 sm:ml-64"
>
  <router-outlet></router-outlet>
</div>
