import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router, RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { User } from '../models/User';
import { UserAuth } from '../models/UserAuth';
import { CognitoService } from '../services/cognito';
import { UserService } from '../services/UserService/user.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterOutlet, MatSlideToggleModule, TranslateModule, CommonModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
  isDarkMode = false;

  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    private translate: TranslateService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.loadTheme();
    this.loadLanguage();

    this.verifyLogIn().then(
      () => {
        this.getUserDetails(
          this.authVerfied.userPoolId,
          this.authVerfied.username,
        );
      },
      (error) => {
        console.log(error);
      },
    );
  }

  async verifyLogIn() {
    await this.cognitoService.getAuthDetails().then((authy) => {
      if (authy.tokens) {
        const str = String(authy.tokens.accessToken.payload['iss']);
        const lastIndex: number = str.lastIndexOf('/');
        const rel: string = str.substring(lastIndex + 1);
        this.authVerfied.userPoolId = rel;
        this.authVerfied.username = String(
          authy.tokens.accessToken.payload['username'],
        );
      } else {
        this.authVerfied.userPoolId = '';
        this.authVerfied.username = '';
      }
    });
  }

  authVerfied: UserAuth = {
    username: '',
    userPoolId: '',
  };

  rechargeUser?: User;

  private async getUserDetails(userPoolId: string, username: string) {
    this.userService
      .getUserInformations(userPoolId, username)
      .subscribe((data) => {
        const rechargeUser = {
          firstName: data.firstName,
          lastName: data.lastName,
          username: data.username,
          userPoolId: data.userPoolId,
          email: data.email,
          phoneNumber: data.phoneNumber,
          birthDate: data.birthDate,
          enabled: data.enabled,
          stripeCustomerId: data.stripeCustomerId,
          addresses: data.addresses,
          vehicules: data.vehicules,
          rfididentification: data.rfididentification,
          id: data.id,
          hasPaymentMethod: data.hasPaymentMethod,
          currentChargeSessionSessionId: data.currentChargeSessionSessionId,
        };
        this.rechargeUser = rechargeUser;
      });
  }

  toggleTheme(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.isDarkMode = input.checked;
    this.applyTheme();
    this.saveTheme();
  }

  private applyTheme(): void {
    if (this.isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }

  private saveTheme(): void {
    localStorage.setItem('theme', this.isDarkMode ? 'dark' : 'light');
  }

  private loadTheme(): void {
    const savedTheme = localStorage.getItem('theme') || 'light';
    this.isDarkMode = savedTheme === 'dark';
    this.applyTheme();
  }

  signOut() {
    this.cognitoService
      .cognitoSignOut()
      .then(() => {
        console.log('Successfully signed out.');
        localStorage.setItem('theme', 'light');
        this.loadTheme();
        this.router.navigate(['/auth/login']);
      })
      .catch((error: unknown) => {
        console.error('Error signing out:', error);
      });
  }

  switchLanguage(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('preferredLanguage', lang);
  }

  private loadLanguage() {
    const savedLanguage = localStorage.getItem('preferredLanguage') || 'fr';
    this.translate.use(savedLanguage);
  }
}
