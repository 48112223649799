import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors } from "@angular/forms";

export class Users {
    public static validatePassword(control: AbstractControl): ValidationErrors | null {
        if (!control.value || /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!#@:;,<>\/''\$%\^&\*\.\?\-_\+\=\(\)])(?=.{8,})/.test(control.value)) {
            return null;
        }
        return { invalidPassword: true };
    }

    public static passwordWithNoSpace(control: AbstractControl): ValidationErrors | null {
        if (!control.value || (!control.value.startsWith(' ') && !control.value.endsWith(' '))) {
            return null;
        }
        return { noSpace: true };
    }

    public static validateSiret(control: AbstractControl): ValidationErrors | null {
        if (!control.value || /^d{14}$/.test(control.value)) {
            return null;
        }
        return { invalidSiret: true };
    }

    public static validateEqual(formGroup: AbstractControl): ValidationErrors | null {
        const password = formGroup.get('password');
        const confirmPassword = formGroup.get('confirmPassword');

        if (!password || !confirmPassword) {
            return null;
        }

        if (password.value !== confirmPassword.value) {
            return { notEqual: true };
        }
        
        return null;
    }
}