import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { User } from '../../models/User';
import { UserAuth } from '../../models/UserAuth';
import { UserService } from '../../services/UserService/user.service';
import { CognitoService } from '../../services/cognito';

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss',
})
export class AccountComponent implements OnInit {
  userInfo: User = {
    username: '',
    email: '',
    addresses: [
      {
        id: 0,
        city: '',
        streetNumber: 0,
        street: '',
        complement: '',
        zipCode: '',
        region: '',
        country: '',
        type: '',
      },
    ],
    vehicules: [
      {
        brandName: '',
        model: '',
        power: '',
        registrationNumber: '',
      },
    ],
    phoneNumber: '',
    userPoolId: '',
    firstName: '',
    lastName: '',
    enabled: false,
    rfididentification: {
      uID: '',
    },
    birthDate: '',
    stripeCustomerId: '',
    hasPaymentMethod: false,
    currentChargeSessionSessionId: null,
  };

  constructor(
    private userService: UserService,
    private cognitoService: CognitoService,
  ) {}

  ngOnInit(): void {
    this.verifyLogIn().then(
      () => {
        this.getUserDetails(
          this.authVerfied.userPoolId,
          this.authVerfied.username,
        );
      },
      (error) => {
        console.log(error);
      },
    );
  }

  async verifyLogIn() {
    await this.cognitoService.getAuthDetails().then((authy) => {
      if (authy.tokens) {
        const str = String(authy.tokens.accessToken.payload['iss']);
        const lastIndex: number = str.lastIndexOf('/');
        const rel: string = str.substring(lastIndex + 1);
        this.authVerfied.userPoolId = rel;
        this.authVerfied.username = String(
          authy.tokens.accessToken.payload['username'],
        );
      } else {
        this.authVerfied.userPoolId = '';
        this.authVerfied.username = '';
      }
    });
  }

  authVerfied: UserAuth = {
    username: '',
    userPoolId: '',
  };

  private async getUserDetails(userPoolId: string, username: string) {
    this.userService
      .getUserInformations(userPoolId, username)
      .subscribe((data) => {
        const rechargeUser = {
          firstName: data.firstName,
          lastName: data.lastName,
          username: data.username,
          userPoolId: data.userPoolId,
          email: data.email,
          phoneNumber: data.phoneNumber,
          birthDate: data.birthDate,
          enabled: data.enabled,
          stripeCustomerId: data.stripeCustomerId,
          addresses: data.addresses,
          vehicules: data.vehicules,
          rfididentification: data.rfididentification,
          id: data.id,
          hasPaymentMethod: data.hasPaymentMethod,
          currentChargeSessionSessionId: data.currentChargeSessionSessionId,
        };

        this.userInfo = rechargeUser;
      });
  }

  getUserInformation(userPoolId: string, username: string) {
    this.userService.getUserInformations(userPoolId, username).subscribe({
      next: (data) => {
        this.userInfo = data;
      },
      error: (error) => {
        console.error(
          'Erreur lors de la récupération des informations utilisateur :',
          error,
        );
      },
    });
  }
}
