<section
  class="flex flex-col justify-between h-full bg-[url('/assets/imgs/bg_auth.png')] bg-no-repeat bg-right bg-contain"
>
  <div class="py-20 px-4 md:px-8">
    <div class="max-w-md md:max-w-2xl mx-auto flex flex-col gap-8">
      <div class="flex flex-col gap-6 items-center justify-center">
        <img
          class="h-32"
          src="../../../assets/logo/efusion-icon.png"
          alt="Logo d'e-Fusion"
        />
        <h2 class="text-center">
          {{ "authentication.register.headline" | translate }}
        </h2>
        <p class="text-center">
          {{ "authentication.register.subheadline" | translate }}
        </p>
      </div>
      <form
        [formGroup]="registerForm"
        (ngSubmit)="onSubmit()"
        class="space-y-6"
      >
        <div class="flex flex-col md:flex-row items-center gap-6">
          <h3>{{ "authentication.register.choice" | translate }}</h3>
          <div class="flex flex-row items-center gap-8">
            <button
              id="individual-account_btn"
              type="button"
              class="bg-primary-500 dark:bg-primary-300 border border-primary-500 dark:border-primary-300 text-usual-50"
            >
              {{ "authentication.register.consumer" | translate }}
            </button>
            <button
              id="professional-account_btn"
              type="button"
              class="bg-transparent border border-primary-500 dark:border-primary-300 text-primary-500 hover:text-usual-50 dark:text-primary-300 hover:bg-primary-500 hover:dark:bg-primary-300"
              (click)="redirectToRegisterPage()"
            >
              {{ "authentication.register.professional" | translate }}
            </button>
          </div>
        </div>
        <div class="flex flex-col md:flex-row gap-4 items-center">
          <div class="flex flex-col gap-2 w-full">
            <!-- FirstName Field Start -->
            <div
              class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
            >
              <label class="font-bold min-w-max" for="firstname"
                >{{
                  "authentication.register.firstname_label" | translate
                }}
                *</label
              >
              <input
                formControlName="firstname"
                type="text"
                id="firstname"
                name="firstname"
                placeholder="{{
                  'authentication.register.firstname_label' | translate
                }}"
                required
                class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
              />
            </div>
            <span
              class="text-negative-500 dark:text-negative-300 font-bold"
              *ngIf="
                registerForm.get('firstname')?.touched &&
                registerForm.get('firstname')?.errors?.['required']
              "
              >{{
                "authentication.register.firstname_required_error" | translate
              }}</span
            >
          </div>
          <!-- FirstName Field End -->
          <div class="flex flex-col gap-2 w-full">
            <!-- LastName Field Start -->
            <div
              class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
            >
              <label class="font-bold min-w-max" for="Lastname"
                >{{
                  "authentication.register.lastname_label" | translate
                }}
                *</label
              >
              <input
                formControlName="lastname"
                type="text"
                id="Lastname"
                name="Lastname"
                placeholder="{{
                  'authentication.register.lastname_label' | translate
                }}"
                required
                class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
              />
            </div>
            <span
              class="text-negative-500 dark:text-negative-300 font-bold"
              *ngIf="
                registerForm.get('lastname')?.touched &&
                registerForm.get('lastname')?.errors?.['required']
              "
              >{{
                "authentication.register.lastname_required_error" | translate
              }}</span
            >
          </div>
          <!-- FirstName Field End -->
        </div>
        <div class="flex flex-col gap-2 w-full">
          <!-- Email Field Start -->
          <div
            class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
          >
            <label class="font-bold min-w-max" for="email"
              >{{ "authentication.register.email_label" | translate }} *</label
            >
            <input
              formControlName="email"
              type="email"
              id="email"
              name="email"
              placeholder="{{
                'authentication.register.email_placeholder' | translate
              }}"
              required
              email
              class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
            />
          </div>
          <span
            class="text-negative-500 dark:text-negative-300 font-bold"
            *ngIf="
              registerForm.get('email')?.touched &&
              registerForm.get('email')?.errors?.['required']
            "
            >{{
              "authentication.register.email_required_error" | translate
            }}</span
          >
          <span
            class="text-negative-500 dark:text-negative-300 font-bold"
            *ngIf="
              registerForm.get('email')?.touched &&
              registerForm.get('email')?.errors?.['email']
            "
            >{{
              "authentication.register.email_syntax_error" | translate
            }}</span
          >
        </div>
        <!-- Email Field End -->
        <div
          formGroupName="passwords"
          class="flex flex-col md:flex-row gap-4 items-center"
        >
          <div class="flex flex-col gap-2 w-full">
            <!-- Password Field Start -->
            <div
              class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
            >
              <label class="font-bold min-w-max" for="password"
                >{{
                  "authentication.register.password_label" | translate
                }}
                *</label
              >
              <input
                matInput
                type="password"
                formControlName="password"
                autocomplete="new-password"
                placeholder="{{
                  'authentication.register.password_placeholder' | translate
                }}"
                [type]="hidePassword ? 'password' : 'text'"
                required
                class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
              />
              <mat-icon
                matSuffix
                class="icon-clickable min-w-max"
                (click)="hidePassword = !hidePassword"
                >{{ hidePassword ? "visibility" : "visibility_off" }}</mat-icon
              >
            </div>
            <mat-error
              *ngIf="
                registerForm.get('passwords.password')?.invalid &&
                registerForm.get('passwords.password')?.touched
              "
            >
              <span
                class="text-negative-500 dark:text-negative-300 font-bold"
                *ngIf="
                  registerForm.get('passwords.password')?.errors?.['required']
                "
                >{{
                  "authentication.register.password_required_error" | translate
                }}</span
              >
              <span
                class="text-negative-500 dark:text-negative-300 font-bold"
                *ngIf="
                  registerForm.get('passwords.password')?.errors?.[
                    'invalidPassword'
                  ]
                "
                >{{
                  "authentication.register.password_syntax_error" | translate
                }}</span
              >
            </mat-error>
          </div>
          <!-- Password Field End -->
          <div class="flex flex-col gap-2 w-full">
            <!-- Confirm Password Field Start -->
            <div
              class="px-4 py-2.5 rounded-lg border border-usual-200 dark:border-usual-700 bg-usual-50 dark:bg-usual-900 flex flex-row gap-2 items-center w-full"
            >
              <label class="font-bold min-w-max" for="password"
                >{{
                  "authentication.register.passwordConfirm_label" | translate
                }}
                *</label
              >
              <input
                matInput
                type="password"
                formControlName="confirmPassword"
                autocomplete="off"
                placeholder="{{
                  'authentication.register.passwordConfirm_placeholder'
                    | translate
                }}"
                [type]="hidePassword ? 'password' : 'text'"
                required
                class="border-0 outline-0 focus:ring-0 w-full bg-transparent rounded-lg caret-primary-500 dark:caret-primary-300"
              />
              <mat-icon
                matSuffix
                class="icon-clickable min-w-max"
                (click)="hidePassword = !hidePassword"
                >{{ hidePassword ? "visibility" : "visibility_off" }}</mat-icon
              >
            </div>
            <mat-error
              *ngIf="
                registerForm.get('passwords.password')?.invalid &&
                registerForm.get('passwords.password')?.touched
              "
            >
              <span
                class="text-negative-500 dark:text-negative-300 font-bold"
                *ngIf="
                  registerForm.get('passwords.confirmPassword')?.errors?.[
                    'required'
                  ]
                "
                >{{
                  "authentication.register.passwordConfirm_required_error"
                    | translate
                }}<br
              /></span>
              <span
                class="text-negative-500 dark:text-negative-300 font-bold"
                *ngIf="registerForm.get('passwords')?.errors?.['notEqual']"
                >{{
                  "authentication.register.passwordConfirm_notEqual_error"
                    | translate
                }}</span
              >
            </mat-error>
          </div>
          <!-- Confirm Password Field End -->
        </div>

        <div class="flex flex-col gap-2 items-center">
          <button
            [disabled]="registerForm.invalid"
            type="submit"
            data-modal-target="authentication-modal"
            data-modal-toggle="authentication-modal"
            class="bg-gradient-to-br disabled:from-usual-100 disabled:to-usual-100 disabled:dark:from-usual-800 disabled:dark:to-usual-800 disabled:text-usual-400 from-primary-500 to-primary-200 hover:from-primary-400 hover:to-secondary-500 text-usual-50"
          >
            {{ "authentication.register.button_create_account" | translate }}
          </button>
          <p class="text-usual-600 text-xs">
            * {{ "authentication.register.required_field_text" | translate }}
          </p>
        </div>
      </form>
    </div>
    <div class="flex flex-col gap-4 items-center justify-center">
      <a
        class="text-primary-500 dark:text-primary-300 hover:text-primary-300 dark:hover:text-primary-500 underline font-bold italic px-4 py-2.2"
        href="/auth/login"
        >{{ "authentication.register.return_login_screen" | translate }}</a
      >
    </div>
  </div>

  <!-- Main modal -->
  <div
    *ngIf="showModal"
    id="authentication-modal"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-4 w-full max-w-md max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div
          class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600"
        >
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
            {{ "authentication.register.modal_headline" | translate }}
          </h3>
          <button
            type="button"
            class="end-2.5 text-primary-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="authentication-modal"
          >
            <svg
              class="flex-shrink-0 w-5 h-5 text-usual-900 transition duration-75 dark:text-usual-50 group-hover:text-usual-900 dark:group-hover:text-primary-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 384 512"
            >
              <path
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              />
            </svg>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-4 md:p-5">
          <form
            [formGroup]="verificationForm"
            (ngSubmit)="onSubmitCode()"
            class="max-w-sm mx-auto"
          >
            <div class="flex justify-center mb-2 space-x-2 rtl:space-x-reverse">
              <div>
                <label for="code1" class="sr-only">First code</label>
                <input
                  formControlName="code1"
                  type="text"
                  maxlength="1"
                  data-focus-input-init
                  data-focus-input-next="code-2"
                  id="code-1"
                  class="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  required
                  (input)="moveFocus($event, 'code-2')"
                />
              </div>
              <div>
                <label for="code2" class="sr-only">Second code</label>
                <input
                  formControlName="code2"
                  type="text"
                  maxlength="1"
                  data-focus-input-init
                  data-focus-input-prev="code-1"
                  data-focus-input-next="code-3"
                  id="code-2"
                  class="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  required
                  (input)="moveFocus($event, 'code-3')"
                  (keydown)="handleBackspace($event, 'code-1')"
                />
              </div>
              <div>
                <label for="code3" class="sr-only">Third code</label>
                <input
                  formControlName="code3"
                  type="text"
                  maxlength="1"
                  data-focus-input-init
                  data-focus-input-prev="code-2"
                  data-focus-input-next="code-4"
                  id="code-3"
                  class="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  required
                  (input)="moveFocus($event, 'code-4')"
                  (keydown)="handleBackspace($event, 'code-2')"
                />
              </div>
              <div>
                <label for="code4" class="sr-only">Fourth code</label>
                <input
                  formControlName="code4"
                  type="text"
                  maxlength="1"
                  data-focus-input-init
                  data-focus-input-prev="code-3"
                  data-focus-input-next="code-5"
                  id="code-4"
                  class="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  required
                  (input)="moveFocus($event, 'code-5')"
                  (keydown)="handleBackspace($event, 'code-3')"
                />
              </div>
              <div>
                <label for="code5" class="sr-only">Fifth code</label>
                <input
                  formControlName="code5"
                  type="text"
                  maxlength="1"
                  data-focus-input-init
                  data-focus-input-prev="code-4"
                  data-focus-input-next="code-6"
                  id="code-5"
                  class="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  required
                  (input)="moveFocus($event, 'code-6')"
                  (keydown)="handleBackspace($event, 'code-4')"
                />
              </div>
              <div>
                <label for="code6" class="sr-only">Sixth code</label>
                <input
                  formControlName="code6"
                  type="text"
                  maxlength="1"
                  data-focus-input-init
                  data-focus-input-prev="code-5"
                  id="code-6"
                  class="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  required
                  (input)="moveFocus($event, 'code-6')"
                  (keydown)="handleBackspace($event, 'code-5')"
                />
              </div>
            </div>
            <p
              id="helper-text-explanation"
              class="mt-2 text-sm text-center text-gray-500 dark:text-gray-400"
            >
              {{ "authentication.register.modal_text_explanation" | translate }}
            </p>
            <button
              type="submit"
              class="w-full bg-primary-500 hover:bg-primary-300 dark:bg-primary-300 dark:hover:bg-primary-500 text-usual-50"
            >
              {{ "authentication.register.modal_button_submit" | translate }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
