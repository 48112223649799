<section class="rounded-2xl flex flex-col gap-8">
  <div
    class="flex px-12 py-4 bg-usual-50 dark:bg-usual-900 dark:text-usual-100 border-usual-200 dark:border-usual-700 justify-center w-full shadow-lg rounded-lg"
  >
    <h2 class="uppercase text-center">
      {{ "pages.assistance.headline" | translate }}
    </h2>
  </div>
  <div class="grid grid-cols-4 gap-6">
    <div
      class="faq-container p-6 bg-usual-50 dark:bg-usual-900 dark:text-usual-100 border-usual-200 dark:border-usual-700 shadow-lg rounded-lg col-span-4 md:row-span-4 md:col-span-2 flex flex-col gap-4"
    >
      <div
        *ngFor="let i of [].constructor(9); let index = index"
        class="flex flex-col p-4 gap-2 border rounded-lg border-usual-950 bg-usual-100 dark:bg-usual-600"
      >
        <div class="cursor-pointer" (click)="toggleQuestion(index)">
          <div class="flex flex-row justify-between">
            <p class="font-bold m-0">
              {{
                "pages.assistance.faq.questions.title-" + (index + 1)
                  | translate
              }}
            </p>
            <svg
              class="h-6"
              [ngClass]="{ 'rotate-180': isQuestionOpen[index] }"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                d="M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"
              />
            </svg>
          </div>
          <div
            [ngClass]="{
              'question-content': true,
              open: isQuestionOpen[index],
            }"
          >
            <p>
              {{
                "pages.assistance.faq.questions.content-" + (index + 1)
                  | translate
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col gap-4 p-6 bg-usual-50 dark:bg-usual-900 dark:text-usual-100 border-usual-200 dark:border-usual-700 shadow-lg rounded-lg col-span-4 md:row-span-2 md:col-span-2"
    >
      <h3 class="uppercase text-primary-200">
        {{ "pages.assistance.demand.headline" | translate }}
      </h3>
      <p>{{ "pages.assistance.demand.subheadline" | translate }}</p>
      <p>{{ "pages.assistance.demand.text" | translate }}</p>
      <div class="flex flex-row pt-6 gap-2 items-center justify-end">
        <a class="underline_animation">{{
          "pages.assistance.demand.navigate_form" | translate
        }}</a>
        <svg
          class="h-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 512"
          fill="currentColor"
        >
          <path
            d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"
          />
        </svg>
      </div>
    </div>
    <div
      class="flex flex-col gap-4 p-6 bg-usual-50 dark:bg-usual-900 dark:text-usual-100 border-usual-200 dark:border-usual-700 shadow-lg rounded-lg col-span-4 md:row-span-2 md:col-span-2"
    >
      <h3 class="uppercase text-primary-200">
        {{ "pages.assistance.contact.headline" | translate }}
      </h3>
      <div>
        <p>
          <strong
            >{{ "pages.assistance.contact.address" | translate }} :
          </strong>
          231 rue pierre et Marie Curie, IOT Valley
        </p>
        <p>
          <strong>{{ "pages.assistance.contact.email" | translate }} : </strong>
          contact&commat;efusion-ve.com
        </p>
        <p>
          <strong>{{ "pages.assistance.contact.phone" | translate }} : </strong>
          0781 66 22 69
        </p>
      </div>
      <div class="flex flex-row gap-2 items-center justify-end">
        <a
          href="https://www.efusion-ve.com/#contact"
          class="underline_animation"
          >{{ "pages.assistance.contact.navigate_form" | translate }}</a
        >
        <svg
          class="h-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 512"
          fill="currentColor"
        >
          <path
            d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"
          />
        </svg>
      </div>
    </div>
  </div>
</section>
