import { CommonModule } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Chart } from 'chart.js/auto';

@Component({
  selector: 'app-consumption',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './consumption.component.html',
  styleUrls: ['./consumption.component.scss'],
})
export class ConsumptionComponent implements AfterViewInit {
  public chart: any;
  public activeButton = 'month';

  currentMonthIndex: number;
  monthLabels: string[];
  monthlyData: number[];

  constructor() {
    this.monthLabels = this.getMonthLabels();
    this.currentMonthIndex = new Date().getMonth();
    this.monthlyData = this.getMonthData();
  }

  ngAfterViewInit(): void {
    this.barChart();
  }

  private barChart() {
    this.chart = new Chart('MyChart', {
      type: 'bar',
      data: {
        labels: this.monthLabels, // Default to monthly labels
        datasets: [
          {
            label: 'Consommation en kWh',
            data: this.monthlyData, // Default to monthly data
            backgroundColor: '#1A3B9A',
            borderRadius: 8,
          },
        ],
      },
      options: {
        aspectRatio: 2.5,
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  }

  private getDayLabels(): string[] {
    return Array.from({ length: 31 }, (_, i) =>
      (i + 1).toString().padStart(2, '0'),
    );
  }

  private getMonthLabels(): string[] {
    return [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ];
  }

  get currentMonth(): string {
    return this.monthLabels[this.currentMonthIndex];
  }

  get currentMonthData(): number {
    return this.monthlyData[this.currentMonthIndex];
  }

  showPreviousMonth(): void {
    this.currentMonthIndex =
      this.currentMonthIndex > 0 ? this.currentMonthIndex - 1 : 11;
  }

  showNextMonth(): void {
    this.currentMonthIndex =
      this.currentMonthIndex < 11 ? this.currentMonthIndex + 1 : 0;
  }

  isCurrentMonth(): boolean {
    const today = new Date();
    return (
      today.getMonth() === this.currentMonthIndex &&
      today.getFullYear() === today.getFullYear()
    );
  }

  private getYearLabels(): string[] {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 5 }, (_, i) =>
      (currentYear - 4 + i).toString(),
    );
  }

  private getDayData(): number[] {
    return [
      20, 18, 19, 17, 15, 20, 22, 23, 25, 27, 26, 28, 29, 30, 28, 27, 26, 25,
      24, 23, 22, 21, 20, 19,
    ];
  }

  private getMonthData(): number[] {
    return [467, 576, 572, 79, 92, 574, 573, 576, 467, 576, 572, 79];
  }

  private getYearData(): number[] {
    return [5500, 6200, 6100, 7000, 7300];
  }

  public updateChartData(labels: string[], data: number[]): void {
    this.chart.data.labels = labels;
    this.chart.data.datasets[0].data = data;
    this.chart.update();
  }

  getDaysInMonth(year: number, month: number): number {
    return new Date(year, month + 1, 0).getDate();
  }

  getRemainingDays(): number {
    const today = new Date();
    const totalDays = this.getDaysInMonth(
      today.getFullYear(),
      today.getMonth(),
    );
    return totalDays - today.getDate();
  }

  onDayClick(): void {
    this.activeButton = 'day';
    this.updateChartData(this.getDayLabels(), this.getDayData());
  }

  onMonthClick(): void {
    this.activeButton = 'month';
    this.updateChartData(this.getMonthLabels(), this.getMonthData());
  }

  onYearClick(): void {
    this.activeButton = 'year';
    this.updateChartData(this.getYearLabels(), this.getYearData());
  }
}
