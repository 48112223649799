import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Address } from '../../models/Address';
import { CheckoutPaymentIntentResponse } from '../../models/CheckoutPaymentIntentResponse';
import { User } from '../../models/User';
import { UserAuth } from '../../models/UserAuth';
import { AddressService } from '../../services/address.service';
import { CognitoService } from '../../services/cognito';
import { StripeService } from '../../services/stripe.service';
import { UserService } from '../../services/UserService/user.service';

@Component({
  selector: 'app-recharge',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './recharge.component.html',
  styleUrl: './recharge.component.scss',
})
export class RechargeComponent implements OnInit {
  enabled = false;
  isToastVisible = false;
  toastType: 'success' | 'danger' = 'success';
  toastMessage = '';
  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private cognitoService: CognitoService,
    private addressService: AddressService,
    private translate: TranslateService,
  ) {}

  authVerfied: UserAuth = {
    username: '',
    userPoolId: '',
  };

  async verifyLogIn() {
    await this.cognitoService.getAuthDetails().then((authy) => {
      if (authy.tokens) {
        const str = String(authy.tokens.accessToken.payload['iss']);
        const lastIndex: number = str.lastIndexOf('/');
        const rel: string = str.substring(lastIndex + 1);
        this.authVerfied.userPoolId = rel;
        this.authVerfied.username = String(
          authy.tokens.accessToken.payload['username'],
        );
      } else {
        this.authVerfied.userPoolId = '';
        this.authVerfied.username = '';
      }
    });
  }

  ngOnInit(): void {
    this.verifyLogIn().then(
      () => {
        this.getUserDetails(
          this.authVerfied.userPoolId,
          this.authVerfied.username,
        );
      },
      (error) => {
        console.log(error);
      },
    );
  }

  EvseForm: FormGroup = this.fb.group({
    evseID: ['', Validators.required],
  });

  startRemoteAuthorization() {
    if (this.rechargeUser && this.EvseForm.valid) {
      const evseID: string = this.EvseForm.value.evseID;
      this.userService
        .startRemoteAuthorization(
          this.rechargeUser?.userPoolId,
          this.rechargeUser.username,
          evseID,
          this.rechargeUser.rfididentification.uID,
        )
        .subscribe(
          (response) => {
            console.log('Authorization started successfully:', response);
            this.userService
              .getUserInformations(
                this.authVerfied.userPoolId,
                this.authVerfied.username,
              )
              .subscribe(
                (userResponse) => {
                  if (userResponse.currentChargeSessionSessionId) {
                    localStorage.setItem('evseID', evseID);
                    this.showSuccessMessage('Charge start successfully');
                    setTimeout(() => {
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    });
                  } else {
                    this.showErrorMessage(
                      "We couldn't honor your request please try again later",
                    );
                  }
                },
                (error) => {
                  console.error('Error fetching user:', error);
                },
              );
          },
          (error) => {
            console.error('Error starting remote authorization:', error);
            this.toastType = 'danger';
            this.translate
              .get('pages.update-account.card-1.toast_success_message')
              .subscribe((translated: string) => {
                this.toastMessage = translated;
                this.isToastVisible = true;
                setTimeout(() => (this.isToastVisible = false), 3000);
                // window.location.reload();
              });
          },
        );
    }
  }

  stopRemoteAuthorization() {
    if (this.rechargeUser) {
      const evseID = String(localStorage.getItem('evseID'));
      this.userService
        .stopRemoteAuthorization(
          this.rechargeUser?.userPoolId,
          this.rechargeUser.username,
          evseID,
          String(this.rechargeUser.currentChargeSessionSessionId),
        )
        .subscribe(
          (response) => {
            console.log('Authorization started successfully:', response);
            this.userService
              .getUserInformations(
                this.authVerfied.userPoolId,
                this.authVerfied.username,
              )
              .subscribe(
                (userResponse) => {
                  if (!userResponse.currentChargeSessionSessionId) {
                    this.showSuccessMessage('Stop Charge successfully');
                    localStorage.removeItem('evseID');
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  } else {
                    this.showErrorMessage(
                      "We couldn't honor your request please try again later",
                    );
                  }
                },
                (error) => {
                  console.error('Error fetching user:', error);
                  this.showErrorMessage(
                    'An error occurred while verifying the session. Please try again later.',
                  );
                },
              );
          },
          (error) => {
            console.error('Error starting remote authorization:', error);
            if (
              error.status === 500 &&
              error.error?.message === 'Unknown error occurred'
            ) {
              this.showErrorMessage(
                'Problème lors de la récupération de vos données, veuillez réessayer.',
              );
            } else {
              this.showErrorMessage("Une erreur inattendue s'est produite.");
            }
          },
        );
    }
  }

  rechargeUser?: User;

  private async getUserDetails(userPoolId: string, username: string) {
    this.userService
      .getUserInformations(userPoolId, username)
      .subscribe(async (data) => {
        const rechargeUser = {
          firstName: data.firstName,
          lastName: data.lastName,
          username: data.username,
          userPoolId: data.userPoolId,
          email: data.email,
          phoneNumber: data.phoneNumber,
          birthDate: data.birthDate,
          enabled: data.enabled,
          stripeCustomerId: data.stripeCustomerId,
          addresses: data.addresses,
          vehicules: data.vehicules,
          rfididentification: data.rfididentification,
          id: data.id,
          hasPaymentMethod: data.hasPaymentMethod,
          currentChargeSessionSessionId: data.currentChargeSessionSessionId,
        };
        this.rechargeUser = rechargeUser;
        if (this.rechargeUser && rechargeUser?.hasPaymentMethod === false) {
          await this.stripeService.initStripe();
        }
      });
  }

  getSecret: CheckoutPaymentIntentResponse = {
    clientSecret: '',
    stripeApiPubket: '',
  };

  async addACard() {
    if (this.rechargeUser?.id) {
      this.userService.setUpIntentForStripe(this.rechargeUser?.id).subscribe({
        next: async (value) => {
          this.getSecret = value;
          await this.createPaymentMethod();
        },
        error: (err) => {
          console.error(err);
          throw err;
        },
      });
    }
  }

  async createPaymentMethod() {
    try {
      const rel = await this.stripeService.createPaymentMethod();
      if (rel.error) {
        console.error('Error creating payment method:', rel.error);
        this.showErrorMessage(rel.error.message);
        return;
      }

      console.log(rel);

      const pol = await this.attachPaymentMethod(
        rel.paymentMethod.id,
        //this.rechargeUser!.stripeCustomerId,
        this.getSecret.clientSecret,
      );
      console.log(pol);
      this.showSuccessMessage('Payment method attached successfully');
    } catch (error) {
      console.error('Unexpected error:', error);
      this.showErrorMessage("Une erreur inattendue s'est produite.");
    }
  }

  showErrorMessage(message: string) {
    this.toastType = 'danger';
    this.toastMessage = message;
    this.isToastVisible = true;
    setTimeout(() => (this.isToastVisible = false), 3000);
  }

  showSuccessMessage(message: string) {
    this.toastType = 'success';
    this.toastMessage = message;
    this.isToastVisible = true;
    setTimeout(() => (this.isToastVisible = false), 3000);
  }

  async attachPaymentMethod(pmi: string, ci: string) {
    console.log(pmi, 'pmi', ci, 'ci');
    this.stripeService.attachPaymentMethodToCustomer(pmi, ci).then(
      (value) => {
        if (value.setupIntent?.status) {
          if (
            value.setupIntent?.status === 'succeeded' &&
            this.rechargeUser?.id
          ) {
            this.userService
              .confirmPaymentMethod(this.rechargeUser?.id)
              .subscribe({
                next: (value) => {
                  this.rechargeUser = value;
                  this.enableUser();
                },
                error(err) {
                  console.error(err);
                },
              });
          }
        }
      },
      (err) => {
        console.error(err);
      },
    );
  }

  // creating address
  AddressForm: FormGroup = this.fb.group({
    city: ['', Validators.required],
    streetNumber: ['', Validators.required],
    street: ['', Validators.required],
    complement: [''],
    zipCode: ['', Validators.required],
    region: [''],
    //country: ['', Validators.required],
  });

  createAddress() {
    if (this.AddressForm.valid) {
      const newAddress: Address = this.AddressForm.value;
      newAddress.type = 'BILLING';
      newAddress.country = 'FRANCE';
      newAddress.userId = this.rechargeUser?.id;
      try {
        String(newAddress);
        this.addressService.createAddress(newAddress).subscribe({
          next: (address: Address) => {
            if (address != null) {
              this.enableUser();
            }
          },
          error: (error: unknown) => {
            console.error(error);
          },
        });
      } catch (err: unknown) {
        console.error(err);
      }
    } else {
      console.error(this.AddressForm);
    }
  }

  enableUser() {
    this.verifyLogIn().then(
      () => {
        this.userService
          .getUserInformations(
            this.authVerfied.userPoolId,
            this.authVerfied.username,
          )
          .subscribe(
            (enabled: User) => {
              this.rechargeUser = enabled;
              if (this.rechargeUser) {
                if (
                  this.rechargeUser.addresses.length !== 0 &&
                  this.rechargeUser.hasPaymentMethod === true &&
                  !this.rechargeUser.enabled
                ) {
                  if (this.rechargeUser?.id) {
                    this.userService
                      .enableUser(this.rechargeUser?.id)
                      .subscribe(
                        (user) => {
                          this.rechargeUser = user;
                        },
                        (error) => {
                          console.error(error);
                        },
                      );
                  }
                }
              }
            },
            (error) => console.error(error),
          );
      },
      (error) => {
        console.log(error);
      },
    );
  }
}
