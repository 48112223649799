import { Injectable } from '@angular/core';
import {
  SetupIntentResult,
  StripeCardElementOptions,
  StripeElementStyle,
} from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { environment } from '../../environments/environment';
import { CheckoutPaymentIntentResponse } from '../models/CheckoutPaymentIntentResponse';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private stripe: any;
  private elements: any;
  private cardElement: any;

  cardy: StripeElementStyle = {
    base: {
      iconColor: '#3498db',
      color: '#f0f0f0',
      fontWeight: '500',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '18px',
      '::placeholder': {
        color: '#ccc',
      },
    },
    invalid: {
      color: '#e74c3c',
    },
  };

  cardStyle: StripeCardElementOptions = {
    style: this.cardy,
    hidePostalCode: true,
  };

  appearance = {
    theme: 'night',
    labels: 'floating',
  };
  options = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    },
  };

  constructor() {
    // this.initStripe();
    // console.log(this.initStripe);
  }

  async initStripe(): Promise<void> {
    this.stripe = await loadStripe(environment.pubKey);
    this.elements = this.stripe.elements({
      appearance: this.appearance,
      options: this.options,
    });
    this.cardElement = this.elements.create('card', this.cardStyle);
    this.cardElement.mount('#card-element');
    console.log(this.cardStyle);
  }
  getSecret: CheckoutPaymentIntentResponse = {
    clientSecret: '',
    stripeApiPubket: '',
  };

  createPaymentMethod(): Promise<any> {
    console.log(this.cardElement);
    return this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardElement,
    });
  }

  attachPaymentMethodToCustomer(
    paymentMethodId: string,
    clientSecret: string,
  ): Promise<SetupIntentResult> {
    console.log(clientSecret);
    return this.stripe.confirmCardSetup(clientSecret, {
      payment_method: paymentMethodId,
    });
  }
}
