import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CognitoService } from '../../services/cognito';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent {
  resetpasswordForm: FormGroup;
  verificationForm: FormGroup;
  showModal = false;
  hidePassword = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private cognitoService: CognitoService,
  ) {
    this.resetpasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.verificationForm = this.fb.group({
      code1: ['', [Validators.required, Validators.maxLength(1)]],
      code2: ['', [Validators.required, Validators.maxLength(1)]],
      code3: ['', [Validators.required, Validators.maxLength(1)]],
      code4: ['', [Validators.required, Validators.maxLength(1)]],
      code5: ['', [Validators.required, Validators.maxLength(1)]],
      code6: ['', [Validators.required, Validators.maxLength(1)]],
      newpassword: ['', [Validators.required, Validators.pattern]],
    });
  }

  get formControls() {
    return this.resetpasswordForm.controls;
  }

  onSubmit() {
    if (this.resetpasswordForm.valid) {
      const email = this.resetpasswordForm.value.email;
      this.cognitoService
        .handleResetPassword(email)
        .then(() => {
          console.log('Form Submitted', this.resetpasswordForm.value);
          this.showModal = true;
        })
        .catch((error: any) => {
          console.log('Error resetting password:', error);
        });
    } else {
      console.log('Form error');
    }
  }

  onSubmitCode() {
    if (this.verificationForm.valid) {
      const code1 = this.verificationForm.get('code1')?.value;
      const code2 = this.verificationForm.get('code2')?.value;
      const code3 = this.verificationForm.get('code3')?.value;
      const code4 = this.verificationForm.get('code4')?.value;
      const code5 = this.verificationForm.get('code5')?.value;
      const code6 = this.verificationForm.get('code6')?.value;

      const code = `${code1}${code2}${code3}${code4}${code5}${code6}`;
      console.log('Verification Code:', code);

      const email = this.resetpasswordForm.value.email;
      const newPassword = this.verificationForm.value.newpassword;
      this.cognitoService
        .handleConfirmResetPassword({
          username: email,
          confirmationCode: code,
          newPassword: newPassword,
        })
        .then(() => {
          console.log('Password reset confirmed for:', email);
          this.showModal = false;
        })
        .catch((error: any) => {
          console.error(
            'Erreur lors de la confirmation de réinitialisation de mot de passe:',
            error,
          );
        });
    } else {
      console.log('Form error in verification');
    }
  }

  closeModal() {
    this.showModal = false;
  }
}
