import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import {
  confirmResetPassword,
  ConfirmResetPasswordInput,
  confirmSignUp,
  fetchAuthSession,
  resetPassword,
  ResetPasswordOutput,
  signIn,
  signOut,
  signUp,
  updatePassword,
  UpdatePasswordInput,
} from 'aws-amplify/auth';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  constructor() {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolClientId: environment.cognito.userPoolWebClientId,
          userPoolId: environment.cognito.userPoolId,
        },
      },
    });
  }

  public async cognitoSignIn(username: string, password: string): Promise<any> {
    try {
      const signedInUserIdentifiers = await signIn({ username, password });
      return signedInUserIdentifiers;
    } catch (err: any) {
      console.error(err);
      throw err;
    }
  }

  public async cognitoSignOut(): Promise<any> {
    try {
      return await signOut();
    } catch (err: any) {
      console.error(err);
    }
  }

  public async cognitoSignUp(username: string, password: string): Promise<any> {
    try {
      const user = await signUp({ username, password });
      console.log(user);
      return user;
    } catch (error) {
      console.log('error signing up:', error);
    }
  }

  public async cognitoConfirmationCode(
    username: string,
    confirmationCode: string,
  ): Promise<any> {
    try {
      const code = await confirmSignUp({ username, confirmationCode });
      console.log(code);
      return code;
    } catch (error) {
      console.log('error confirmation code:', error);
    }
  }

  public async handleResetPassword(username: string): Promise<any> {
    try {
      const output = await resetPassword({ username });
      this.handleResetPasswordNextSteps(output);
    } catch (error) {
      console.log('error reset password:', error);
    }
  }

  private handleResetPasswordNextSteps(output: ResetPasswordOutput): void {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.log(
          `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`,
        );
        break;
      case 'DONE':
        console.log('Successfully reset password.');
        break;
      default:
        console.log('Unknown reset password step');
    }
  }

  public async handleConfirmResetPassword({
    username,
    confirmationCode,
    newPassword,
  }: ConfirmResetPasswordInput) {
    try {
      await confirmResetPassword({ username, confirmationCode, newPassword });
    } catch (error) {
      console.log(error);
    }
  }

  public async getAuthDetails() {
    const user = await fetchAuthSession();
    return user;
  }

  public async handleUpdatePassword({
    oldPassword,
    newPassword,
  }: UpdatePasswordInput) {
    try {
      await updatePassword({ oldPassword, newPassword });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}
