<section
  class="p-6 h-full bg-usual-50 dark:bg-usual-900 shadow-lg dark:text-usual-100 border-usual-200 dark:border-usual-700 pt-4 space-y-2 rounded-2xl relative overflow-hidden"
>
  <div class="flex flex-col gap-6 overflow-x-auto overflow-y-auto">
    <table>
      <thead>
        <tr class="bg-blue-gray-100 text-usual-900 dark:text-usual-100">
          <td class="py-3 px-2 md:px-4 text-left font-bold">Action</td>
          <td class="py-3 px-2 md:px-4 text-left font-bold">Statut</td>
          <td
            class="py-3 px-2 md:px-4 text-left font-bold hidden md:table-cell"
          >
            Date de facturation
          </td>
          <td
            class="py-3 px-2 md:px-4 text-left font-bold hidden md:table-cell"
          >
            Numéro de facture
          </td>
          <td
            class="py-3 px-2 md:px-4 text-left font-bold hidden md:table-cell"
          >
            Email
          </td>
          <td class="py-3 px-2 md:px-4 text-left font-bold">Montant</td>
        </tr>
      </thead>
      <tbody class="text-usual-700">
        <tr *ngFor="let item of data" class="border-b border-blue-gray-200">
          <td class="py-3 px-4">
            <a class="text-center flex justify-center"
              ><svg
                class="h-8"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                <path
                  d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 232V334.1l31-31c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l31 31V232c0-13.3 10.7-24 24-24s24 10.7 24 24z"
                /></svg
            ></a>
          </td>
          <td class="py-3 px-4">
            <span
              class="text-center align-baseline inline-flex px-4 py-3 mr-auto items-center font-semibold text-[.95rem] leading-none rounded-lg"
              [ngClass]="{
                'text-primary-400 bg-primary-100':
                  item.status === 'In Progress',
                'text-positive-400 bg-positive-100': item.status === 'Done',
                'text-negative-400 bg-negative-100': item.status === 'Denied',
              }"
            >
              {{ item.status }}
            </span>
          </td>
          <td class="py-3 px-4 hidden md:table-cell">
            {{ item.dateFacturation }}
          </td>
          <td class="py-3 px-4 hidden md:table-cell">
            {{ item.numeroFacture }}
          </td>
          <td class="py-3 px-4 hidden md:table-cell">
            contact..efusion-ve.com
          </td>
          <td class="py-3 px-4">5.20 €</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
