<section class="flex flex-col justify-top gap-8 w-full h-full">
  <div class="grid grid-cols-12 gap-6">
    <div
      class="col-span-12 md:col-span-6 flex flex-col gap-8 bg-usual-50 dark:bg-usual-800 dark:text-usual-100 shadow-lg rounded-lg px-12 py-4"
    >
      <div class="flex flex-row justify-between items-center">
        <h2 class="text-primary-200">
          {{ "pages.account.card-1.headline" | translate }}
        </h2>
        <a href="dashboard/update-account"
          ><svg
            class="svg-inline--fa fa-pen hover:text-primary-200 h-4"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="pen"
            data-popover-target="popover-default"
            type="button"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"
            ></path>
          </svg>
        </a>
        <div
          data-popover
          id="popover-default"
          role="tooltip"
          class="absolute z-10 invisible inline-block w-40 text-center text-sm text-usual-500 transition-opacity duration-300 bg-usual-50 border border-usual-200 rounded-lg shadow-sm opacity-0 dark:text-usual-400 dark:border-usual-600 dark:bg-usual-800"
        >
          <div class="px-3 py-2">
            <p>{{ "pages.account.card-1.overmsg_edit" | translate }}</p>
          </div>
          <div data-popper-arrow></div>
        </div>
      </div>
      <div class="flex flex-row gap-8 items-center">
        <svg
          class="h-16 text-primary-800 dark:text-primary-500"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            d="M406.5 399.6C387.4 352.9 341.5 320 288 320l-64 0c-53.5 0-99.4 32.9-118.5 79.6C69.9 362.2 48 311.7 48 256C48 141.1 141.1 48 256 48s208 93.1 208 208c0 55.7-21.9 106.2-57.5 143.6zm-40.1 32.7C334.4 452.4 296.6 464 256 464s-78.4-11.6-110.5-31.7c7.3-36.7 39.7-64.3 78.5-64.3l64 0c38.8 0 71.2 27.6 78.5 64.3zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-272a40 40 0 1 1 0-80 40 40 0 1 1 0 80zm-88-40a88 88 0 1 0 176 0 88 88 0 1 0 -176 0z"
          />
        </svg>
        <div class="flex flex-col">
          <p class="text-lg font-medium">
            {{ userInfo.firstName }} {{ userInfo.lastName }}
          </p>
        </div>
      </div>
      <div class="flex flex-col gap-6">
        <div class="flex flex-row justify-between gap-2">
          <p>
            {{ "pages.account.card-1.email" | translate }}
          </p>
          <p class="text-usual-500 text-xs font-bold italic">
            {{ userInfo.email }}
          </p>
        </div>
        <div class="flex flex-row justify-between gap-2">
          <p>{{ "pages.account.card-1.address" | translate }}</p>
          <p
            *ngIf="userInfo.addresses && userInfo.addresses.length > 0"
            class="text-usual-500 text-xs font-bold italic text-right"
          >
            <span *ngIf="userInfo && userInfo.addresses.length > 0">
              <div *ngIf="userInfo.addresses[0]">
                {{ userInfo.addresses[0].streetNumber }}
                {{ userInfo.addresses[0].street }}<br />
                <span *ngIf="userInfo.addresses[0].complement">
                  {{ userInfo.addresses[0].complement }}<br
                /></span>
                {{ userInfo.addresses[0].zipCode }}
                {{ userInfo.addresses[0].city }},
                <span *ngIf="userInfo.addresses[0].region">
                  {{ userInfo.addresses[0].region }}<br
                /></span>
                {{ userInfo.addresses[0].country }}<br />
              </div>
            </span>
          </p>
          <p
            *ngIf="!userInfo.addresses || userInfo.addresses.length === 0"
            class="text-usual-500 text-xs font-bold italic"
          >
            {{ "pages.account.card-1.empty_address" | translate }}
          </p>
        </div>
        <div class="flex flex-row justify-between gap-2">
          <p>{{ "pages.account.card-1.phone" | translate }}</p>
          <p class="text-usual-500 text-xs font-bold italic">
            {{ userInfo.phoneNumber ? userInfo.phoneNumber : "Aucun numéro" }}
          </p>
        </div>
        <a
          class="px-4 py-2.5 bg-primary-500 hover:bg-primary-300 dark:bg-primary-300 dark:hover:bg-primary-500 text-usual-50 rounded-lg italic text-center font-bold md:w-1/2 mx-auto"
          href="dashboard/update-password"
          >{{ "pages.account.card-1.button_change_password" | translate }}</a
        >
      </div>
    </div>
    <div
      class="col-span-12 md:col-span-6 flex flex-col items-center justify-center gap-8 bg-usual-50 dark:bg-usual-800 dark:text-usual-100 shadow-lg rounded-lg px-12 py-4"
    >
      <img
        class="w-80"
        src="../../../assets/imgs/efusion-card_rotate.png"
        alt="efusion card"
      />
      <div class="flex flex-row px-4 md:px-0 gap-6 mx-auto items-center">
        <a
          class="px-4 py-2.5 bg-primary-500 hover:bg-primary-300 dark:bg-primary-300 dark:hover:bg-primary-500 text-usual-50 rounded-lg italic text-center font-bold"
          >{{ "pages.account.card-1.button_command_card" | translate }}</a
        >
        <a
          class="text-primary-500 hover:text-primary-300 dark:text-primary-300 dark:hover:text-primary-500 underline italic font-bold text-center"
          >{{ "pages.account.card-1.button_problem_card" | translate }}</a
        >
      </div>
      <div *ngIf="userInfo && userInfo.rfididentification; else noCard">
        <p *ngIf="userInfo.rfididentification.uID">
          {{ "pages.account.card-1.rdifidentification" | translate }} :
          {{ userInfo.rfididentification.uID }}
        </p>
      </div>
      <ng-template #noCard>
        <p>{{ "pages.account.card-1.empty_rdifidentification" | translate }}</p>
      </ng-template>
    </div>
  </div>
</section>
