import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CognitoService } from '../../services/cognito';

@Component({
  selector: 'app-update-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
  ],
  templateUrl: './update-password.component.html',
  styleUrl: './update-password.component.scss',
})
export class UpdatePasswordComponent {
  updatePasswordForm: FormGroup;
  public errorMessage: string | null = null;
  public hidePassword = true;
  isToastVisible = false;
  toastType: 'success' | 'danger' = 'success';
  toastMessage = '';

  constructor(
    private fb: FormBuilder,
    private cognitoService: CognitoService,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.updatePasswordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  async onSubmit() {
    if (this.updatePasswordForm.invalid) {
      return;
    }

    const { oldPassword, newPassword } = this.updatePasswordForm.value;

    try {
      await this.cognitoService.handleUpdatePassword({
        oldPassword,
        newPassword,
      });
      this.toastType = 'success';
      this.translate
        .get('pages.update-account.card-1.toast_success_message')
        .subscribe((translated: string) => {
          this.toastMessage = translated;
          this.isToastVisible = true;
          setTimeout(
            () => (
              (this.isToastVisible = false),
              this.router.navigate(['/dashboard/mon-compte'])
            ),
            3000,
          );
        });
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === 'LimitExceededException') {
          this.toastType = 'danger';
          this.toastMessage = error.message;
          this.isToastVisible = true;
          setTimeout(() => (this.isToastVisible = false), 5000);
        } else if (error.name === 'NotAuthorizedException') {
          this.toastType = 'danger';
          this.translate
            .get('pages.update-password.toast_wrong_error_message')
            .subscribe((translated: string) => {
              this.toastMessage = translated;
              this.isToastVisible = true;
              setTimeout(() => (this.isToastVisible = false), 3000);
            });
        } else {
          this.toastType = 'danger';
          this.translate
            .get('pages.update-password.toast_other_error_message')
            .subscribe((translated: string) => {
              this.toastMessage = translated;
              this.isToastVisible = true;
              setTimeout(() => (this.isToastVisible = false), 3000);
            });
        }
      } else {
        this.toastType = 'danger';
        this.translate
          .get('pages.update-password.toast_unknown_error_message')
          .subscribe((translated: string) => {
            this.toastMessage = translated;
            this.isToastVisible = true;
            setTimeout(() => (this.isToastVisible = false), 3000);
          });
      }
    }
  }
}
