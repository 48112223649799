<section
  class="p-6 bg-usual-50 dark:bg-usual-900 drop-shadow-lg dark:text-usual-100 border-usual-200 dark:border-usual-700 rounded-2xl flex flex-col gap-2"
>
  <div class="flex justify-between items-center">
    <!-- Heading -->
    <h3 class="dark:text-usual-50">
      {{ "pages.consumption.headline" | translate }}
    </h3>
  </div>

  <div class="flex flex-col gap-6">
    <!-- Content -->

    <div class="flex mx-auto gap-4 items-center w-full max-w-[400px]">
      <!-- Heading Content with Calendar -->
      <svg
        class="svg-inline--fa fa-arrow-left text-primary-500 w-6 cursor-pointer"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="arrow-left"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        data-fa-i2svg=""
        (click)="showPreviousMonth()"
      >
        <path
          fill="currentColor"
          d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
        ></path>
      </svg>
      <div class="flex flex-col text-center flex-grow min-w-[150px]">
        <h5>{{ currentMonth }}</h5>
        <div
          *ngIf="isCurrentMonth()"
          class="flex flex-row gap-4 justify-center"
        >
          <p class="text-usual-600">
            {{ "pages.consumption.daysRemainingCounterText1" | translate }}
            {{ getRemainingDays() }}
            {{ "pages.consumption.daysRemainingCounterText2" | translate }}
          </p>
        </div>
      </div>
      <svg
        class="svg-inline--fa fa-arrow-right text-primary-500 w-6 cursor-pointer"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="arrow-right"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        data-fa-i2svg=""
        (click)="showNextMonth()"
      >
        <path
          fill="currentColor"
          d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
        ></path>
      </svg>
    </div>

    <div class="grid grid-cols-6 md:grid-cols-12 gap-4">
      <!-- values -->

      <div
        class="col-span-6 md:col-span-4 flex flex-col items-center pb-4 border-b md:pb-0 md:border-b-0 md:pr-6 md:border-r border-usual-200"
      >
        <!-- Item 1 - Consumption -->
        <svg
          class="svg-inline--fa fa-charging-station h-5 text-primary-500"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="charging-station"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M96 0C60.7 0 32 28.7 32 64V448c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32s-14.3-32-32-32V304h16c22.1 0 40 17.9 40 40v32c0 39.8 32.2 72 72 72s72-32.2 72-72V252.3c32.5-10.2 56-40.5 56-76.3V144c0-8.8-7.2-16-16-16H544V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H480V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H432c-8.8 0-16 7.2-16 16v32c0 35.8 23.5 66.1 56 76.3V376c0 13.3-10.7 24-24 24s-24-10.7-24-24V344c0-48.6-39.4-88-88-88H320V64c0-35.3-28.7-64-64-64H96zM216.9 82.7c6 4 8.5 11.5 6.3 18.3l-25 74.9H256c6.7 0 12.7 4.2 15 10.4s.5 13.3-4.6 17.7l-112 96c-5.5 4.7-13.4 5.1-19.3 1.1s-8.5-11.5-6.3-18.3l25-74.9H96c-6.7 0-12.7-4.2-15-10.4s-.5-13.3 4.6-17.7l112-96c5.5-4.7 13.4-5.1 19.3-1.1z"
          ></path>
        </svg>
        <div class="flex items-center gap-1 text-primary-300">
          <h2>{{ currentMonthData }}</h2>
          <h3>kWh / h</h3>
        </div>
        <p class="text-usual-600">
          {{ "pages.consumption.electricityConsumed" | translate }}
        </p>
      </div>

      <div class="col-span-6 md:col-span-4 flex flex-col items-center">
        <!-- Item 2 - Target -->
        <svg
          class="svg-inline--fa fa-crosshairs-simple h-5 text-primary-500"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="crosshairs-simple"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M445.3 224H384c-17.7 0-32 14.3-32 32s14.3 32 32 32h61.3C431.9 368.4 368.4 431.9 288 445.3V384c0-17.7-14.3-32-32-32s-32 14.3-32 32v61.3C143.6 431.9 80.1 368.4 66.7 288H128c17.7 0 32-14.3 32-32s-14.3-32-32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V128c0 17.7 14.3 32 32 32s32-14.3 32-32V66.7C368.4 80.1 431.9 143.6 445.3 224zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256z"
          ></path>
        </svg>
        <div class="flex items-center gap-1 text-primary-300">
          <h2>8</h2>
          <h3>kWh / h</h3>
        </div>
        <p class="text-usual-600">
          {{ "pages.consumption.electricityPlanned" | translate }}
        </p>
      </div>

      <div
        class="col-span-6 md:col-span-4 flex flex-col items-center pt-4 border-t md:pt-0 md:border-t-0 md:pl-6 md:border-l border-usual-200"
      >
        <!-- Item 3 - Subscription -->
        <svg
          class="svg-inline--fa fa-chart-mixed h-5 text-primary-500"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="chart-mixed"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M500 89c13.8-11 16-31.2 5-45s-31.2-16-45-5L319.4 151.5 211.2 70.4c-11.7-8.8-27.8-8.5-39.2 .6L12 199c-13.8 11-16 31.2-5 45s31.2 16 45 5L192.6 136.5l108.2 81.1c11.7 8.8 27.8 8.5 39.2-.6L500 89zM160 256V448c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-17.7-14.3-32-32-32s-32 14.3-32 32zM32 352v96c0 17.7 14.3 32 32 32s32-14.3 32-32V352c0-17.7-14.3-32-32-32s-32 14.3-32 32zm288-64c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32s32-14.3 32-32V320c0-17.7-14.3-32-32-32zm96-32V448c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-17.7-14.3-32-32-32s-32 14.3-32 32z"
          ></path>
        </svg>
        <div class="flex items-center gap-1 text-primary-300">
          <h2>50</h2>
          <h3>%</h3>
        </div>
        <p class="text-usual-600">
          {{ "pages.consumption.subscriptionConsumption" | translate }}
        </p>
      </div>
    </div>

    <div class="flex flex-col relative h-80 md:h-full w-full">
      <canvas id="MyChart"></canvas>
    </div>
    <!-- Diagram -->

    <div class="flex flex-col md:flex-row gap-4">
      <div
        class="flex items-center gap-4 px-4 border border-usual-200 rounded-lg"
      >
        <label for="sort" class="font-bold">{{
          "pages.consumption.unity" | translate
        }}</label>
        <select
          disabled
          name="sort"
          class="border-none bg-usual-50 dark:bg-usual-900"
        >
          <option>kWh / h</option>
        </select>
      </div>
      <div class="flex gap-1 items-center mx-auto">
        <button
          type="button"
          [ngClass]="{
            'bg-primary-500 dark:bg-primary-300 text-usual-50':
              activeButton === 'day',
            'bg-transparent border text-primary-500': activeButton !== 'day',
          }"
          class="border border-primary-500 dark:border-primary-300 rounded-lg hover:bg-primary-300 dark:hover:bg-primary-500 hover:text-usual-50 hover:border-primary-300 dark:hover:border-primary-500 flex gap-2"
          (click)="onDayClick()"
        >
          {{ "pages.consumption.day" | translate }}
        </button>
        <button
          type="button"
          [ngClass]="{
            'bg-primary-500 dark:bg-primary-300 text-usual-50':
              activeButton === 'month',
            'bg-transparent border text-primary-500': activeButton !== 'month',
          }"
          class="border border-primary-500 dark:border-primary-300 rounded-lg hover:bg-primary-300 dark:hover:bg-primary-500 hover:text-usual-50 hover:border-primary-300 dark:hover:border-primary-500 flex gap-2"
          (click)="onMonthClick()"
        >
          {{ "pages.consumption.month" | translate }}
        </button>
        <button
          type="button"
          [ngClass]="{
            'bg-primary-500 dark:bg-primary-300 text-usual-50':
              activeButton === 'year',
            'bg-transparent border text-primary-500': activeButton !== 'year',
          }"
          class="border border-primary-500 dark:border-primary-300 rounded-lg hover:bg-primary-300 dark:hover:bg-primary-500 hover:text-usual-50 hover:border-primary-300 dark:hover:border-primary-500 flex gap-2"
          (click)="onYearClick()"
        >
          {{ "pages.consumption.year" | translate }}
        </button>
      </div>
    </div>

    <hr />

    <h4>{{ "pages.consumption.history" | translate }}</h4>

    <div id="listtab">
      <!-- List tab -->
      <table class="table-auto w-full">
        <thead>
          <tr>
            <th>{{ "pages.consumption.thead_date" | translate }}</th>
            <th>
              {{ "pages.consumption.thead_consumption" | translate }} [ kWh / h
              ]
            </th>
            <th>Relève [ kWh / h ]</th>
            <th>Type de relève</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center odd:bg-usual-100 odd:dark:bg-usual-800 even:bg-usual-50 even:dark:bg-usual-900"
          >
            <td class="p-3">23 / 01 / 2023</td>
            <td class="font-bold text-primary-500 m-3">1</td>
            <td>1980</td>
            <td>Index réel</td>
          </tr>
          <tr
            class="text-center odd:bg-usual-100 odd:dark:bg-usual-800 even:bg-usual-50 even:dark:bg-usual-900"
          >
            <td class="p-3">23 / 01 / 2023</td>
            <td class="font-bold text-primary-500 m-3">1</td>
            <td>1980</td>
            <td>Index réel</td>
          </tr>
          <tr
            class="text-center odd:bg-usual-100 odd:dark:bg-usual-800 even:bg-usual-50 even:dark:bg-usual-900"
          >
            <td class="p-3">23 / 01 / 2023</td>
            <td class="font-bold text-primary-500 m-3">1</td>
            <td>1980</td>
            <td>Index réel</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
